import { SortModel, TableCreationResult } from '../../../../web/src/contracts/table';
import { useObservable } from '../../../../web/src/hooks/useObservable';
import { useSubscription } from '../../../../web/src/hooks/useSubscription';
import { Column, predefinedColumns, Row } from '../../types';
import {
    FieldDescriptor,
    Key,
    ProxyClient,
    RowField,
    RowIdField,
    RowUpdate,
    SimpleTableClient,
    SortOrder,
    stringifyKey,
} from '@thinkalpha/table-client';
import { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';

function getKeyFromTableProp(table: TableCreationResult | undefined): Key | undefined {
    if (!table) {
        return undefined;
    } else if ('tableCookie' in table) {
        return table.key;
    } else {
        return table;
    }
}

const rowKeyToColumnNameMap = {
    rowId: 'rowId',
    ticker: 'ticker',
    name: 'Name',
    last: 'Last_Price',
    change: 'Change_Close',
    percentageChange: 'Change_Close%',
    marketCap: 'MarketCap',
    volume: 'Volume',
};

function getTableClientPropertyName(key: keyof Row): string {
    return rowKeyToColumnNameMap[key] || key.toString();
}

export class RowStateManager {
    private columns: string[];
    private rows: Row[];
    private subscribers: Map<number, (row: Row) => void>;
    private isDirty: Map<number, boolean>;
    private updates: null | ReturnType<typeof setInterval>;

    #mapRowKeyToColumnId: (key: keyof Row) => string;

    constructor(opts: { columnNameToIdMap: Record<string, string> }) {
        this.columns = predefinedColumns;
        this.rows = [];
        this.subscribers = new Map();
        this.isDirty = new Map();
        this.updates = null;

        this.#mapRowKeyToColumnId = (key: keyof Row) =>
            opts.columnNameToIdMap[rowKeyToColumnNameMap[key]] || (key as string);
    }

    setRowCount(rowCount: number) {
        this.rows = this.rows.slice(0, rowCount);
    }

    setColumns(columns: string[]) {
        this.columns = columns;
    }

    updateRows(update: RowUpdate) {
        const rowIndex = (update as RowUpdate)[RowField];

        const rowId = (update as RowUpdate)[RowIdField];
        if (rowId === undefined) {
            return;
        }

        const currentRow = this.rows[rowIndex];
        // either there's no row, no row data, or the row is a complete replacement
        if (!currentRow || rowId !== currentRow.rowId) {
            const newRow = this.#createData(rowId, update);

            this.rows.splice(rowIndex, 1, newRow);
            this.isDirty.set(rowIndex, true);
        } else {
            const newRow = { ...currentRow, ...this.#createData(rowId, update) };

            if (!isEqual(newRow, currentRow)) {
                this.rows.splice(rowIndex, 1, newRow);
                this.isDirty.set(rowIndex, true);
            }
        }
    }

    #createData(rowId: number | undefined, rowData: any): Row {
        const data = { rowId };
        this.columns.forEach((x) => {
            data[x] = rowData[this.#mapRowKeyToColumnId(x) as any];
        });
        return data as any as Row;
    }

    getColumns(): string[] {
        return this.columns;
    }

    getRows(): Row[] {
        return this.rows;
    }

    subscribe(rowIndex: number, callback: (row: Row) => void) {
        this.subscribers.set(rowIndex, callback);

        return () => {
            this.subscribers.delete(rowIndex);
        };
    }

    run() {
        // Update rows which were changed once every 250ms
        this.updates = setInterval(() => {
            this.subscribers.forEach((callback, rowIndex) => {
                if (this.isDirty.get(rowIndex)) {
                    callback(this.rows[rowIndex]);
                    this.isDirty.set(rowIndex, false);
                }
            });
        }, 250);
    }

    stop() {
        this.updates && clearInterval(this.updates);
    }
}

export const useRowStateManager = (
    tableResult: TableCreationResult | undefined,
    client: ProxyClient,
    tc: SimpleTableClient,
    rowCount: number,
    sortBy: Column,
    sortOrder: SortOrder,
    validationColumnNameLookup: Map<string, string> | undefined,
    columnNameToIdMap: Record<keyof Row, string>,
) => {
    const tableKey = getKeyFromTableProp(tableResult);

    const tableResult$ = useObservable(tableResult);
    useSubscription(
        () =>
            tableResult$.subscribe((tableResult) => {
                if (tableResult && 'tableCookie' in tableResult) {
                    client.addAccessCookie(tableResult.tableCookie);
                }
            }),
        [client, tc],
    );

    useEffect(() => {
        const propertyName = columnNameToIdMap[getTableClientPropertyName(sortBy as keyof Row)];
        const sortModel: SortModel = {
            [propertyName]: sortOrder,
        };

        tc.reconfigure(tableKey, undefined, sortModel);
    }, [stringifyKey(tableKey), sortBy, sortOrder]);

    const rowStateManager = useRef(new RowStateManager({ columnNameToIdMap }));

    // TODO: When `columnNameToIdMap` changes, register the new map with the rowStateManager

    useEffect(() => {
        rowStateManager.current.run();
        return () => rowStateManager.current.stop();
    }, []);

    const [fields, setFields] = useState<FieldDescriptor[]>();
    useSubscription(() => {
        return tc.descriptor$.subscribe((fields) => setFields(fields));
    }, [tc]);

    useEffect(() => {
        const columns = [
            ...predefinedColumns,
            ...(fields && validationColumnNameLookup
                ? fields.filter((x) => validationColumnNameLookup.get(x.name)).map((x) => x.name)
                : []),
        ];

        rowStateManager.current.setColumns(columns);
    }, [fields, validationColumnNameLookup]);

    useSubscription(() =>
        tc.update$.subscribe((update) => {
            rowStateManager.current.updateRows(update);
        }),
    );

    useEffect(() => {
        tc.bounds = { firstRow: 0, windowSize: rowCount };
    }, [rowCount]);

    return [rowStateManager];
};
