import App from './App';
import './index.scss';
import { installIntoGlobal } from 'iterator-helpers-polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';

declare global {
    interface Window {
        Cypress?: true;
    }
}

installIntoGlobal();

async function init() {
    try {
        const root = createRoot(document.getElementById('app')!);
        root.render(<App />);
    } catch (e) {
        console.error('Error in init', e);
    }
}

init();
