import { StrategyInstance } from '../../../../web/src/contracts/strategy';
import { UniverseRecord } from '../../../../web/src/contracts/universe';
import { useEvent } from '../../../../web/src/hooks/useEvent';
import { useSubscription } from '../../../../web/src/hooks/useSubscription';
import { usePureProxyClient } from '../../../../web/src/services/table';
import { RowCountSelect } from '../../RowCountSelect';
import { UniverseSelect } from '../../UniverseSelect';
import { useSelector } from '../../hooks';
import { Column } from '../../types';
import { ResultsTable } from './ResultsTable';
import { copyToClipboardCSV, createCSV } from './copyToClipboardCSV';
import { copyToClipboardText } from './copyToClipboardText';
import { downloadContent } from './downloadContent';
import { useRowStateManager } from './useRowStateManager';
import { useUniverseInfo } from './useUniverseInfo';
import { FormControl, MenuItem, Select, Tooltip } from '@mui/material';
import { SimpleTableClient, SortOrder } from '@thinkalpha/table-client';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const DEFAULT_ROW_COUNT = 20;

interface InnerResultsPageProps {
    runInfo: StrategyInstance;
    validationColumnNameLookup: Map<string, string> | undefined;
    universe: UniverseRecord | undefined;
    setUniverse: (universe: UniverseRecord) => void;
}

export const InnerResultsPage: React.FC<InnerResultsPageProps> = ({
    runInfo,
    validationColumnNameLookup,
    universe,
    setUniverse,
}) => {
    const token = useSelector((app) => app.auth.accessToken);
    const columnNameToIdMap = useSelector((state) => state.app.defaultColumnTemplateMapping!.nameToId);

    // TODO: Use column IDs somehow
    const [sortBy, setSortBy] = useState<Column>(Column.ticker);
    const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.ascending);
    const [rowsAvailableCount, setRowsAvailableCount] = useState<number>(0);
    const [rowCount, setRowCount] = useState<number>(DEFAULT_ROW_COUNT);

    const universeInfo = useUniverseInfo(universe?.id);
    const client = usePureProxyClient();
    const tc = useMemo(() => new SimpleTableClient(client), [client]);

    useEffect(() => {
        if (token && client) {
            client.token = token.token;
        }
    }, [client, token]);

    useEffect(() => {
        // set initial bounds on the client, so that we get descriptors
        tc.bounds = { firstRow: 0, windowSize: 20 };

        // dispose tc on new tc
        return () => tc.dispose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tc]);

    useSubscription(
        () =>
            tc.rowCount$.subscribe((count) => {
                setRowsAvailableCount(count);
            }),
        [tc, setRowsAvailableCount],
    );

    const [rowStateManager] = useRowStateManager(
        /* tableClient */ runInfo,
        client,
        tc,
        rowCount,
        sortBy,
        sortOrder,
        validationColumnNameLookup,
        columnNameToIdMap,
    );

    const tableRef = useRef<HTMLTableElement>(null);

    const [showCopyTooltip, setShowCopyTooltip] = useState(false);
    const [copyBtnTooltipText, setCopyBtnTooltipText] = useState('');

    const copyTimeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
    const copyCSV = useEvent(() => {
        copyToClipboardCSV(rowStateManager.current.getRows());
        setCopyBtnTooltipText('Copied CSV to clipboard');
        setShowCopyTooltip(true);
        if (copyTimeoutRef.current) clearTimeout(copyTimeoutRef.current);
        copyTimeoutRef.current = setTimeout(() => {
            setShowCopyTooltip(false);
            copyTimeoutRef.current = undefined;
        }, 1500);
    });

    const downloadCSV = useEvent(() => {
        const csv = createCSV(rowStateManager.current.getRows());
        downloadContent('result.csv', csv, 'text/csv;charset=utf-8;');
    });

    const copyText = useEvent(() => {
        if (!tableRef.current) return;

        copyToClipboardText(tableRef.current);
        setCopyBtnTooltipText('Copied Table to clipboard');
        setShowCopyTooltip(true);

        if (copyTimeoutRef.current) clearTimeout(copyTimeoutRef.current);
        copyTimeoutRef.current = setTimeout(() => {
            setShowCopyTooltip(false);
            copyTimeoutRef.current = undefined;
        }, 1500);
    });

    const selectIconComponent = useCallback(() => null, []);
    const selectRenderValue = useCallback(() => <i className="fas fa-copy" />, []);

    return (
        <div className="results mt-12">
            <div className="settings-bar flex flex-col md:flex-row md:justify-between">
                <div className="flex items-center self-start">
                    <UniverseSelect selectedUniverse={universe} setUniverse={setUniverse} />
                    <RowCountSelect rowCount={rowCount} setRowCount={setRowCount} />
                    <div className="mr-[1rem] whitespace-nowrap text-xs text-stone-500">
                        Matched {rowsAvailableCount} of {universeInfo?.universeSize}
                    </div>
                </div>
                <div className="flex items-center self-end">
                    <Tooltip
                        title={<span className="text-xs">{copyBtnTooltipText}</span>}
                        open={showCopyTooltip}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        placement="top"
                    >
                        <FormControl>
                            <Select
                                className="results-copy"
                                displayEmpty={true}
                                renderValue={selectRenderValue}
                                value="value"
                                variant="standard"
                                disableUnderline
                                classes={{
                                    select: 'py-0 mb-[3px] text-xs text-stone-500 pr-0',
                                    icon: 'text-stone-500',
                                }}
                                MenuProps={{
                                    container: document.getElementById('app')!,
                                    classes: {
                                        paper: 'bg-white text-stone-900',
                                    },
                                }}
                                IconComponent={selectIconComponent}
                            >
                                <MenuItem onClick={copyText} className="hover:bg-stone-100">
                                    <i className="fas fa-copy" />
                                    &nbsp;Copy table
                                </MenuItem>
                                <MenuItem onClick={copyCSV} className="hover:bg-stone-100">
                                    <i className="fas fa-copy" />
                                    &nbsp;Copy as CSV
                                </MenuItem>
                                <MenuItem onClick={downloadCSV} className="hover:bg-stone-100">
                                    <i className="fas fa-download" />
                                    &nbsp;Download CSV
                                </MenuItem>
                                <MenuItem value="value" style={{ display: 'none' }}></MenuItem>
                            </Select>
                        </FormControl>
                    </Tooltip>
                </div>
            </div>
            <div className="my-6 text-xs text-stone-500 md:hidden">
                Drag table to scroll for additional rows and columns
            </div>
            <ResultsTable
                rowStateManager={rowStateManager.current}
                rowCount={rowCount}
                tableRef={tableRef}
                validationColumnNameLookup={validationColumnNameLookup}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
            />
        </div>
    );
};
