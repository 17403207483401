import { useCallback, useInsertionEffect, useRef } from 'react';

export function useEvent<TCallback extends undefined | ((...args: any[]) => any)>(callback?: TCallback): TCallback {
    const unstableCallbackRef = useRef<TCallback | undefined>(callback);

    useInsertionEffect(() => {
        unstableCallbackRef.current = callback;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const event = useCallback<NonNullable<TCallback>>(
        function (...args: any[]) {
            return unstableCallbackRef.current?.(...args);
        } as unknown as NonNullable<TCallback>,
        [],
    );

    return event;
}
