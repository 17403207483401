import { MenuItem, TextField } from '@mui/material';
import React, { ChangeEvent, FC, useCallback } from 'react';

interface RowCountSelectProps {
    rowCount: number;
    setRowCount: React.Dispatch<React.SetStateAction<number>>;
}

export const RowCountSelect: FC<RowCountSelectProps> = ({ rowCount, setRowCount }) => {
    const onChange = useCallback(
        ({ target: { value } }: ChangeEvent<{ value: unknown }>) => {
            setRowCount(value as number);
        },
        [setRowCount],
    );

    return (
        <TextField
            select
            size="small"
            variant="standard"
            className="row-count-select"
            value={rowCount}
            onChange={onChange}
            InputProps={{
                disableUnderline: true,
                classes: {
                    root: 'text-xs text-stone-500',
                },
            }}
            SelectProps={{
                MenuProps: {
                    container: document.getElementById('app')!,
                    classes: {
                        paper: 'bg-white text-stone-900',
                    },
                },
                classes: {
                    select: 'p-0',
                    icon: 'text-stone-500',
                },
            }}
            classes={{
                root: 'w-[3.5rem]',
            }}
        >
            {[5, 10, 20, 50, 100].map((rowCount) => {
                return (
                    <MenuItem key={rowCount} value={rowCount} className="hover:bg-stone-100">
                        {rowCount}
                    </MenuItem>
                );
            })}
        </TextField>
    );
};
