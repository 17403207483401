import { IndicatorImportRefViewModel } from '@/contracts/dictionary-view-model';

export enum NlpQueryType {
    operand = 'operand',
    condition = 'condition',
    date = 'date',
}

export enum NlpQueryParseType {
    query = 'query',
    date = 'date',
}

export interface NlpSimilarRequestData {
    key?: string;
    input: string;
    isConditional: boolean;
}

export interface NlpSimilarResponseData {
    input: string;
    similar: NlpSuggestion[];
}

export interface NlpParseRequestData {
    type: NlpQueryParseType;
    key?: string;
    input: string;
    isConditional: boolean;
    clientId?: string;
    isDone?: boolean;
}

export interface NlpParseResponseData {
    docId: string | null;
    input: string;
    formula: string | null;
    aliases: IndicatorImportRefViewModel[] | null;
    modified: {
        reason: string;
        from: string;
        to: string;
    } | null;
    key: string;
    similar: NlpSuggestion[];
    clientId: string;
}

export interface NlpAutocompleteRequestData {
    input: string;
    key: string;
    isConditional: boolean;
}

export interface NlpAutocompleteResponseData {
    autocomplete: string[];
}

export interface NlpSuggestion {
    input: string;
    formula: string;
    score?: number;
    type: 'operands' | 'conditions' | 'timeframes';
}

export interface NlpSuggestionWithMockId extends NlpSuggestion {
    id: string;
}

export interface NlpExample extends NlpSuggestion {
    type: 'operands' | 'conditions' | 'timeframes';
}

export interface NlpExampleResponse {
    data: NlpExample[];
    count: number;
    total: number;
}

export interface StockSymbol {
    name: string;
    ticker: string;
}

export interface CompanySuggestionCategory {
    category_description: string;
    category_label: string;
    category_name: string;
    comm_ticker_count: number;
    comm_tickers_list: StockSymbol[];
    pref_ticker_count: number;
    pref_tickers_list: StockSymbol[];
}

export interface CompanySuggestionsResponseData {
    result: CompanySuggestionCategory[];
}
