import { createInstance } from '../../api';
import appConfig from '../../config';
import { replaceFormulaNodes } from '../../if-then/if-then-helpers';
import { createImportAlias } from '../dictionary/imports-helpers';
import { rxifyAxios } from '../http';
import {
    CompanySuggestionsResponseData,
    NlpAutocompleteRequestData,
    NlpAutocompleteResponseData,
    NlpExample,
    NlpExampleResponse,
    NlpParseRequestData,
    NlpParseResponseData,
    NlpQueryParseType,
    NlpQueryType,
    NlpSimilarRequestData,
    NlpSimilarResponseData,
} from './contracts';
import NlpAPIError, { mapNlpAPIError } from './error';
import { IndicatorViewModel, IndicatorImportViewModel } from '@/contracts/dictionary-view-model';
import { AstNodeType, KnownAstNode } from '@thinkalpha/language-services';
import { AxiosResponse } from 'axios';
import { memoize } from 'lodash';
import loglevel from 'loglevel';
import { firstValueFrom, Observable, of, OperatorFunction, pipe, ReplaySubject } from 'rxjs';
import { catchError, map, share, switchMap } from 'rxjs/operators';
import { ResourceQueryResponseWithMeta } from 'src/contracts/resource-query';
import { UnreachableCaseError } from 'ts-essentials';

const log = loglevel.getLogger('nlp-parsing');
log.setDefaultLevel(loglevel.levels.INFO);

const axios = createInstance({
    baseURL: appConfig.nlpApi,
    validateStatus: (status) => (status >= 200 && status <= 299) || status == 422, // 422 Unprocessable Entity is "successful" here
});

const rxAxios = rxifyAxios(axios, mapNlpAPIError());

export function fetchSuggestions(input: string, clientId: string): Observable<NlpParseResponseData> {
    return rxAxios
        .post<NlpParseResponseData | NlpAPIError>('/v1/parse', {
            type: 'query',
            clientId,
            isConditional: true,
            input,
        })
        .pipe(
            map((x) => {
                if (x.status === 422) {
                    return (x.data as NlpAPIError).detail.similar;
                } else {
                    return x.data as NlpParseResponseData;
                }
            }),
        );
}

export function replaceAliasInFormula(formula: string, previousAlias: string, newAlias: string): string {
    return replaceFormulaNodes(formula, [], (node: KnownAstNode | null) => {
        if (node && node.type === AstNodeType.call && node.source === previousAlias) {
            return { ...node, source: newAlias };
        }

        return node;
    });
}

/**
 * A function that processes the aliases and formula recieved from the NLP API
 * with respect to an array of already in-use imports, ensuring that duplicate
 * imports are stripped, duplicate alias names are renamed, and if any
 * adjustments are made to the NLP API aliases, that each alias referenced
 * in the NLP formula is updated.
 *
 * @param input the NlpParseResponseData to process
 * @param existingImports an array of imports from the context where NLP is being used
 *
 * @returns a new NlpParseResponseData with processed formula and aliases properties
 */
export function stripImportDuplicatesFromNlpParseResult(
    input: NlpParseResponseData,
    existingImports: IndicatorImportViewModel[],
): NlpParseResponseData {
    if (!input.formula || !input.aliases) return input;

    const { formula, aliases } = input.aliases.reduce(
        (previous, current) => {
            // If import already exists on the strategy but with a different alias,
            // replace alias in formula with already existing alias
            const importMatch = existingImports.find((previousImport) => {
                return (
                    previousImport.id === current.id &&
                    previousImport.version === current.version &&
                    previousImport.alias !== current.alias
                );
            });

            if (importMatch) {
                return {
                    ...previous,
                    formula: replaceAliasInFormula(previous.formula, current.alias, importMatch.alias),
                };
            }

            // If alias already exists on the strategy but for a different import,
            // create a new alias before adding the new import, and replace the
            // old alias with the new alias in the formula
            const aliasMatch = existingImports.find((previousImport) => {
                return (
                    previousImport.alias === current.alias &&
                    previousImport.id !== current.id &&
                    previousImport.version !== current.version
                );
            });

            if (aliasMatch) {
                // Construct necessary Indicator properties for createImportAlias and cast as Indicator
                const alias = createImportAlias(
                    { symbol: current.alias, key: null } as IndicatorViewModel,
                    existingImports,
                );
                return {
                    formula: replaceAliasInFormula(previous.formula, current.alias, alias),
                    aliases: [
                        ...previous.aliases,
                        {
                            ...current,
                            alias,
                        },
                    ],
                };
            }

            // Else, no issues exist, so just add the new import
            return { ...previous, aliases: [...previous.aliases, current] };
        },
        { formula: input.formula, aliases: [] },
    );

    return { ...input, formula, aliases };
}

export function getAutocompletePhrase(queryType: NlpQueryType): OperatorFunction<string, string | undefined> {
    return pipe(
        switchMap((input) => {
            if (!input) return of(undefined);

            const request: NlpAutocompleteRequestData = {
                key: 'ticker',
                input,
                isConditional: queryType === 'condition',
            };

            return rxAxios.post<NlpAutocompleteResponseData | NlpAPIError>('/v1/autocomplete', request).pipe(
                map(({ data }: AxiosResponse<NlpAutocompleteResponseData>) => {
                    return data.autocomplete.length ? data.autocomplete[0] : undefined;
                }),
                catchError((error: NlpAPIError | Error) => {
                    log.debug(`NLP /autocomplete encountered an error parsing ${input}. ${error}`);
                    return of(undefined);
                }),
            );
        }),
    );
}

export function getSimilarQueries(queryType: NlpQueryType): OperatorFunction<string, NlpSimilarResponseData> {
    return pipe(
        switchMap((input) => {
            if (!input) return of({ similar: [], input });

            const key = 'ticker';
            const isConditional = queryType === 'condition';
            const request: NlpSimilarRequestData = { key, input, isConditional };

            return rxAxios.post<NlpSimilarResponseData | NlpAPIError>('/v1/similar', request).pipe(
                map((x) => {
                    const success = x.data as NlpSimilarResponseData;
                    return { ...success, input };
                }),
                catchError((error: NlpAPIError | Error) => {
                    log.debug(`NLP /parse encountered an error parsing ${input}. ${error}`);
                    return of({ similar: [], input });
                }),
            );
        }),
    );
}

async function parseNaturalLanguage(
    input: string,
    queryType: NlpQueryType,
    clientId: string,
): Promise<NlpParseResponseData> {
    const key = 'ticker';
    const type = queryType === 'date' ? NlpQueryParseType.date : NlpQueryParseType.query;
    const isConditional = queryType === 'condition';

    const genericResponseData: NlpParseResponseData = {
        docId: null,
        input,
        formula: null,
        aliases: [],
        similar: [],
        modified: null,
        key,
        clientId,
    };

    const request: NlpParseRequestData = { type, key, input, isConditional, clientId, isDone: true };

    return await firstValueFrom(
        rxAxios.post<NlpParseResponseData | NlpAPIError>('/v1/parse', request).pipe(
            map((x) => {
                if (x.status === 422) {
                    // couldn't process the user input, so there's instead suggestions
                    const err = x.data as NlpAPIError;
                    return { ...genericResponseData, formula: null, similar: err.detail.similar };
                } else {
                    const success = x.data as NlpParseResponseData;
                    return { ...success, input };
                }
            }),
            catchError((error: NlpAPIError | Error) => {
                log.debug(`NLP /parse encountered an error parsing ${input}. ${error}`);
                return of({ ...genericResponseData, formula: null, similar: [] });
            }),
        ),
    );
}
export const parseNaturalLanguageMemo = memoize(parseNaturalLanguage);

export const commonNlpExamples$: Observable<NlpExampleResponse> = rxAxios
    .get<NlpExampleResponse>('/v1/examples', { params: { shortlist: true, count: 1000, caseType: 'all_examples' } })
    .pipe(
        map((x) => x.data),
        share({
            connector: () => new ReplaySubject(1),
        }),
    );

export const getNlpExamplesQuery = async (type: NlpQueryType): Promise<ResourceQueryResponseWithMeta> => {
    const { data, count } = (
        await axios.get<NlpExampleResponse>('/v1/examples', {
            params: { shortlist: false, count: 100000, page: 1, caseType: mapTypeToContract(type) },
        })
    ).data;

    return { results: data.map((x) => ({ id: x.input, input: x.input, formula: x.formula })), count };
};

export function mapTypeToContract(type: NlpQueryType): NlpExample['type'] {
    switch (type) {
        case NlpQueryType.operand:
            return 'operands';
        case NlpQueryType.date:
            return 'timeframes';
        case NlpQueryType.condition:
            return 'conditions';
        default:
            throw new UnreachableCaseError(type);
    }
}

export async function getCompanySuggestions(query: string) {
    return (await axios.post<CompanySuggestionsResponseData>('/v1/companies', { input: query })).data;
}
