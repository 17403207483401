import { RootState } from '..';
import { fetchSuggestions } from '../../../../web/src/services/nlp';
import { NlpParseResponseData, ResearchStatus } from '../../types';
import {
    ResearchNaturalLanguageAction,
    setFormulasForQueries,
    setNaturalLanguageStatus,
    setSuggestions,
} from '../actions/queries';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { firstValueFrom } from 'rxjs';

export function* researchNaturalLanguage(action: ResearchNaturalLanguageAction) {
    const query = action.query.trim();

    const currentStatus: ResearchStatus | undefined = yield select(
        (state: RootState) => state.queries.researchStatusByQuery[query],
    );

    if (currentStatus === 'researching' || currentStatus === 'complete') {
        return;
    }

    yield put(setNaturalLanguageStatus(query, 'researching'));

    const results: NlpParseResponseData = yield call(firstValueFrom, fetchSuggestions(action.query, 'askAlpha'));

    const suggestions = results.similar ?? [];
    const suggestionTexts = suggestions.map((x) => x.input);

    yield put(setSuggestions(action.query, suggestionTexts));

    const formulasByQueries = suggestions.reduce(
        (allFormulas, curr) => ({ ...allFormulas, [curr.input]: curr.formula }),
        { [query]: results.formula },
    );

    yield all([
        put(setFormulasForQueries(formulasByQueries)),
        put(setSuggestions(action.query, suggestionTexts)),
        put(setNaturalLanguageStatus(query, 'complete')),
    ]);
}

export function* querySagas() {
    yield all([takeEvery('researchNaturalLanguage', researchNaturalLanguage)]);
}
