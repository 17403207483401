// import thinkAlphaLogoDark from '../../web/src/assets/ThinkAlphaLogo.svg';
import thinkAlphaLogo from '../../web/src/assets/ThinkAlphaNewLogoGreyType.svg';
import React from 'react';
import { Link } from 'react-router-dom';

interface LogoLinkProps {
    className?: string;
}

export const LogoLink: React.FC<LogoLinkProps> = ({ className }) => {
    return (
        <Link to="/">
            <img className={`my-12 block w-80 ${className ?? ''}`} src={thinkAlphaLogo} />
            {/* <img className={`hidden dark:block w-80 my-12 ${className ?? ''}`} src={thinkAlphaLogoDark} /> */}
        </Link>
    );
};
