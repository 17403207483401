import { appConfig } from '../config/appConfig';
import { datadogRum } from '@datadog/browser-rum';
import { UserDetails } from 'src/store/types';

if (typeof window !== 'undefined' && window.Cypress === undefined) {
    datadogRum.init({
        applicationId: 'eaee0948-8c7b-4d28-b6ad-867daa65eb69',
        clientToken: 'pub3f629456eb0a1285661f0336b1435e72',
        site: 'datadoghq.com',
        service: 'platform-ui',
        env: appConfig.environmentName,

        // Specify a version number to identify the deployed version of your application in Datadog
        version: appConfig.commit,
        sampleRate: 100,
        sessionReplaySampleRate: 20,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
}

export const setUserDetails = (user?: UserDetails) => {
    if (typeof 'window' !== undefined && window.Cypress) return;
    if (user) {
        datadogRum.setUser({
            id: user.id,
            name: `${user.profile.firstName} ${user.profile.lastName}`,
            email: user.email,
        });
    } else {
        datadogRum.clearUser();
    }
};

export const submitErrorToDatadog = (error: Error, context?: object) => {
    datadogRum.addError(error, context);
};

export const submitActionToDatadog = (actionType: string, context?: object) => {
    datadogRum.addAction(actionType, context);
};
