import { useEffect } from 'react';
import { SubscriptionLike } from 'rxjs';

export function useSubscription(subscribe: () => SubscriptionLike | undefined, deps: any[] = []) {
    useEffect(() => {
        const sub = subscribe?.();

        return () => {
            sub?.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
