import { getEnvOverride } from './overrides';
import loglevel from 'loglevel';
import { isNative } from 'src/features/native';

/**
 * Sampling rate for production environments.
 */
const prodSampleRate = 10.0;

export interface ConfigType {
    auth: string;
    api: string;
    appVersion: string | undefined;
    nlpApi: string;
    environmentName: string;
    quoteLikeServices?: {
        quote: string;
        pos: string;
        posAgg: string;
        pnl: string;
        pnlAgg: string;
        symbol: string;
    };
    gaussApi: string;
    tradingApi: string;
    tableApi: string;
    tracesSampleRate: number;
    oneSignalAppId: string;
    backtestSettingsSchemaVersion: number;
    quantApi: string;

    /**
     * URI of the legacy locates deployment. When specified, UI uses it instead of platform-ws.
     */
    legacyLocatesApi?: string;
    locatesApi?: string;

    /**
     * FusionAuth configuration parameters
     */
    fusionAuthUrl: string;
    fusionAuthTenantId: string;
    fusionAuthApplicationId: string;
    tableProxy: string;
    riskTableProxy: string;
}

/**
 *
 * @param env the name of the environment; must be a valid API environment
 * @returns partial configuration object
 */
const shared = (env: string) =>
    ({
        /**
         * APIS (env-driven)
         */
        auth: `https://identity-ws.${env}.thinkalpha.io`,
        api: `https://api.${env}.thinkalpha.io`,
        quoteLikeServices: {
            quote: `https://quote-ws-quote.${env}.thinkalpha.io`,
            pos: `https://quote-ws-pos.${env}.thinkalpha.io`,
            posAgg: `https://quote-ws-pos-agg.${env}.thinkalpha.io`,
            pnl: `https://quote-ws-pnl.${env}.thinkalpha.io`,
            pnlAgg: `https://quote-ws-pnl-agg.${env}.thinkalpha.io`,
            symbol: `https://quote-ws-symbol.${env}.thinkalpha.io`,
        },
        gaussApi: `https://gauss-ws.${env}.thinkalpha.io`,
        tradingApi: `https://trading-ws-nextgen.${env}.thinkalpha.io`,
        tableApi: `https://table-ws-ng.${env}.thinkalpha.io`,
        nlpApi: `https://nlp.${env}.thinkalpha.io`,
        environmentName: `${env}`,
        /**
         * VARS (base, expect overrides)
         */
        tracesSampleRate: 1.0,
        appVersion: undefined,
        oneSignalAppId: '',
        quantApi: `https://quant-ng-api.${env}.thinkalpha.io`,
        backtestSettingsSchemaVersion: 1,
        locatesApi: `https://locates-ws.${env}.thinkalpha.io`,
        legacyLocatesApi: undefined, // only available in QA and above,
        fusionAuthUrl: 'https://login.dev.thinkalpha.io/',
        fusionAuthTenantId: '876cf895-b361-4e57-a475-8568d483991d',
        fusionAuthApplicationId: 'fafbb3c2-528c-4802-824d-d64c7d9bf44a',
        tableProxy: `https://table-ws.${env}.thinkalpha.io`,
        riskTableProxy: `https://table-ws.${env}.thinkalpha.io`,
    }) satisfies Partial<ConfigType>;

const demoHrefEnvRegExp = new RegExp(/^https?:\/\/([\w-]*)\.dev./);
const hrefEnvRegExp = new RegExp(/^https?:\/\/([\w-]*)\./i);

export const getHrefEnv = (): string | undefined => {
    if (isNative) return undefined;

    // ToDo: Get askalpha spun out entirely from app config
    if (location.host === 'askalpha.io' || location.host === 'www.askalpha.io') {
        return 'prod';
    }

    // If it's a demo branch, set env to dev
    // The demo branch demo-example1 will be available at example1.dev.thinkalpha.io
    if (location.href.match(demoHrefEnvRegExp)) {
        return 'dev';
    }

    const env = location.href.match(hrefEnvRegExp);
    if (env && typeof env[1] === 'string' && !!env[1]) {
        return env[1];
    }

    return undefined;
};

declare global {
    const APP_CONFIG_NAME: string; // TODO: deprecate; use "environment" terminology
    const APP_VERSION: string;

    interface Window {
        appConfig: ConfigType;
    }
}

let appConfig: ConfigType & { commit?: string };
try {
    const envOverride = getEnvOverride();

    if (envOverride) {
        loglevel.info(`Overriding env config from \`${APP_CONFIG_NAME}\` to \`${envOverride}\``);
    }

    const ENV_TO_USE = envOverride || APP_CONFIG_NAME;

    if (ENV_TO_USE === 'prod') {
        appConfig = {
            ...shared('prod'),
            tracesSampleRate: prodSampleRate,
            legacyLocatesApi: 'wss://locates.thinkalpha.io',
            oneSignalAppId: 'd6b8dc34-ce45-4542-b216-20fc1e7c1cc9',
            fusionAuthUrl: 'https://login.prod.thinkalpha.io',
            fusionAuthTenantId: 'd8272274-b48e-4ffc-9016-3677dc518501',
            fusionAuthApplicationId: '583d8656-e8c3-46fd-963f-d460a43f2a55',
            riskTableProxy: 'https://table-ws-risk.prod.thinkalpha.io',
        };
    } else if (ENV_TO_USE === 'prod-alpha') {
        appConfig = {
            ...shared('prod-alpha'),
            tracesSampleRate: prodSampleRate,
            legacyLocatesApi: 'wss://locates.thinkalpha.io',
            oneSignalAppId: 'd6b8dc34-ce45-4542-b216-20fc1e7c1cc9',
            fusionAuthUrl: 'https://login.prod-alpha.thinkalpha.io',
            fusionAuthTenantId: '8d60dc83-8ec7-4efa-9757-2d2b55d19079',
            fusionAuthApplicationId: '3bb96e65-0bcd-485e-88b3-588f6f7577ce',
            riskTableProxy: 'https://table-ws-risk.prod-alpha.thinkalpha.io',
        };
    } else if (ENV_TO_USE === 'dev') {
        appConfig = {
            ...shared('dev'),
            legacyLocatesApi: 'wss://locates-and-preborrows-app.dev.ny.thinkalpha.io',
            oneSignalAppId: `195612b8-82eb-484e-b79a-88f19d31d3a0`,
            fusionAuthUrl: 'https://login.dev.thinkalpha.io',
            fusionAuthTenantId: '876cf895-b361-4e57-a475-8568d483991d',
            fusionAuthApplicationId: 'fafbb3c2-528c-4802-824d-d64c7d9bf44a',
        };
    } else if (ENV_TO_USE === 'qa') {
        appConfig = {
            ...shared('qa'),
            // legacyLocatesApi: 'wss://qalocates.thinkalpha.io',
            oneSignalAppId: 'd896a86c-a767-4e69-b502-e22b281f3a03',
            fusionAuthUrl: 'https://login.qa.thinkalpha.io',
            fusionAuthTenantId: '9f750007-6387-4be0-bc77-aa6f7b6ca3e3',
            fusionAuthApplicationId: '9bf2770a-cf32-4db8-8306-be024128c2ea',
        };
    } else if (ENV_TO_USE === 'uat') {
        appConfig = {
            ...shared('uat'),
            tracesSampleRate: prodSampleRate / 2,
            legacyLocatesApi: 'wss://uatlocates.thinkalpha.io',
            oneSignalAppId: '0a5f5618-5dc6-4ecd-aa5c-c716ac5f9959',
            fusionAuthUrl: 'https://login.uat.thinkalpha.io',
            fusionAuthTenantId: 'a88da876-23be-426d-b5f4-2c7670a149d9',
            fusionAuthApplicationId: 'af3704b2-bee3-41f6-8dc6-208d56dbb413',
        };
    } else {
        appConfig = {
            ...shared('dev'),
            api: 'https://localhost:3000',
            // tradingApi: `//localhost:3001`,
            // locatesApi: 'https://localhost:12830',
            appVersion: 'local',
            environmentName: 'local',
            oneSignalAppId: '73096378-e9ee-4c8b-96ce-15a618bdd03b',
            fusionAuthUrl: 'https://login.dev.thinkalpha.io',
            fusionAuthTenantId: '876cf895-b361-4e57-a475-8568d483991d',
            fusionAuthApplicationId: 'fafbb3c2-528c-4802-824d-d64c7d9bf44a',
        };
    }

    const env = getHrefEnv();

    if (env && env !== APP_CONFIG_NAME) {
        // TODO: Normalize href usage into overall configuration workflow
        loglevel.info(
            `Using ${APP_CONFIG_NAME} config, but not on .${APP_CONFIG_NAME}. href; using "${env}" as current environment`,
        );
        appConfig = {
            ...appConfig,
            ...shared(env),
        };
    }
} catch (e) {
    if (e instanceof ReferenceError) {
        loglevel.error('Failed to identify the app config name! Defaulting to dev.');
        appConfig = {
            ...shared('dev'),
        };
    } else {
        throw e;
    }
}

if (typeof APP_VERSION !== 'undefined') {
    appConfig.appVersion = APP_VERSION;
} else {
    loglevel.warn('Missing app version constant');
}

if (typeof window !== 'undefined') {
    window.appConfig = appConfig;
}

export { appConfig };
export default appConfig;
appConfig.commit = '605153f47edafc8ac0e7bbb4ecd0717ee3731d18';
