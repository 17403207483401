import { IndicatorImportViewModel } from '@/contracts/dictionary-view-model';
import { Strategy } from 'src/contracts/strategy';
import {
    AccountsWidgetModel,
    AggregatedPositionsWidgetModel,
    ChannelData,
    ExecutionsWidgetModel,
    IfThenBuilderWidgetModel,
    Layout,
    LocatesWidgetModel,
    MessagesWidgetModel,
    OrderBlotterWidgetModel,
    OrderEntryWidgetModel,
    OrdersWidgetModel,
    PositionsWidgetModel,
    StrategyWidgetModel,
    TableWidgetModel,
    TimeSeriesWidgetModel,
    WidgetContainer,
    WidgetTab,
    Workspace,
} from 'src/contracts/workspace';

export * from './sagaContext';

export type FeatureFlagSet = {
    disableSessionBarrier?: boolean;
    modernLocates?: boolean;
    envOverride?: string;
    keyboardCommands?: boolean;
};

export type FeatureFlag = keyof FeatureFlagSet;

export interface UserDetails {
    id: string;
    profile: { firstName: string; lastName: string };
    username: string;
    email: string;
    isActive: boolean;
    /**
     * @deprecated
     */
    isAdmin: boolean;
    /**
     * isSuperuser is a flag for global, unrestricted superuser permissions.
     */
    isSuperuser: boolean;
    isDeveloper: boolean;
}

export interface WorkspaceViewModel extends Omit<Workspace, 'containers' | 'channels'> {
    containerIds: string[];
    channelIds: string[];
    activeContainerId?: string;
    isDirty: boolean;
    maxLayoutHeight: number;
    isDefaultWorkspace: boolean;
}

export interface ChannelDataViewModel extends ChannelData {
    channelId: string;
    strategies?: (Strategy<IndicatorImportViewModel> | string)[] | null;
}

export interface ContainerViewModel extends Omit<WidgetContainer, 'tabs' | 'layout'> {
    tabIds: string[];
}

export interface ContainerLayoutViewModel extends Layout {
    containerId: string;
}

/**
 * WidgetViewModel is going to be the UI-relevant data for each widget type
 * There is a divergence between what is "needed" on the UI
 * and what is "needed" on the server, and often we need to map between them
 * For example, take the StrategyWidgetViewModel -- it's almost a StrategyWidgetModel.
 * The server really doesn't care about the full imports on the strategy,
 * but on the UI side we want all of the imports expanded so that we can
 * ascertain the data type of each validation column from the strategy
 */

export interface StrategyWidgetViewModel extends StrategyWidgetModel {
    strategies?: (Strategy<IndicatorImportViewModel> | string)[];
}

export interface IfThenBuilderWidgetViewModel extends IfThenBuilderWidgetModel {
    strategy: string | Strategy<IndicatorImportViewModel> | null;
}

export type WidgetViewModel =
    | AccountsWidgetModel
    | AggregatedPositionsWidgetModel
    | ExecutionsWidgetModel
    | IfThenBuilderWidgetViewModel
    | LocatesWidgetModel
    | MessagesWidgetModel
    | OrderBlotterWidgetModel
    | OrderEntryWidgetModel
    | OrdersWidgetModel
    | PositionsWidgetModel
    | StrategyWidgetViewModel
    | TableWidgetModel
    | TimeSeriesWidgetModel;

export type WidgetViewModelKinds = Array<WidgetViewModel['type']>[number];

export type InferWidgetViewModel<T extends WidgetViewModelKinds> = Extract<WidgetViewModel, { type: T }>;

export interface WidgetTabViewModel extends WidgetTab {
    containerId: string;
    channelId: string | null;
    isActive: boolean;
    widget: WidgetViewModel;
}

export const ApplicationMenu = {
    loggedOutOpen: 'logged-out::open',
    loggedOutClosed: 'logged-out::closed',
    loggedInClosed: 'logged-in::closed',
    dashboard: 'dashboard',
};

export type ApplicationMenuType = (typeof ApplicationMenu)[keyof typeof ApplicationMenu];

export type ModalType =
    | 'verify-leaving-workspace'
    | 'verify-saving-workspace'
    | 'get-new-workspace-name'
    | 'workspace-library';
