import { ConcreteUniverse } from '../../../../web/src/contracts/universe';
import { useSubscription } from '../../../../web/src/hooks/useSubscription';
import { usePureProxyClient } from '../../../../web/src/services/table';
import { getUniverseById } from '../../../../web/src/services/universes';
import { useSelector } from '../../hooks';
import { Key, SimpleTableClient } from '@thinkalpha/table-client';
import { useEffect, useMemo, useState } from 'react';

type UniverseInfo = ConcreteUniverse & { universeSize?: number } & { table?: Key };
export function useUniverseInfo(id: string | undefined) {
    const [universeInfo, setUniverseInfo] = useState<UniverseInfo | undefined>();
    // load universe by id in order to get access to .table
    useEffect(() => {
        if (!id) {
            return;
        }

        getUniverseById(id).subscribe((info) => {
            setUniverseInfo(info); // we have info.table populated in the response
        });
    }, [id]);

    // subscribe to universe table & get rowsCount
    const client = usePureProxyClient();
    const tc = useMemo(() => new SimpleTableClient(client), [client]);
    const token = useSelector((app) => app.auth.accessToken);
    useEffect(() => {
        if (token && client) {
            client.token = token.token;
        }
    }, [client, token]);

    useEffect(() => {
        if (tc && universeInfo) {
            tc.key = universeInfo.table;
        }
    }, [universeInfo, tc]);

    useEffect(() => {
        tc.bounds = { firstRow: 0, windowSize: 1 }; // one row only
        return () => tc.dispose();
    }, [tc]);

    useSubscription(
        () =>
            tc.rowCount$.subscribe((count) => {
                setUniverseInfo((prev) => {
                    return (
                        prev && {
                            ...prev,
                            universeSize: count,
                        }
                    );
                });
            }),
        [tc],
    );
    return universeInfo;
}
