import axios from '../api';
import appConfig from '../config';
import { CreateTablePayload, TableCreationResult } from '@/contracts/table';
import { ConnectionStatus, ProxyClient, RawClient } from '@thinkalpha/table-client';
import loglevel from 'loglevel';
import { filter } from 'rxjs/operators';

const client = new ProxyClient(appConfig.tableProxy);

const logger = loglevel.getLogger('table-service');

client.connectionStatus$.pipe(filter((x) => x === ConnectionStatus.badToken)).subscribe(() => {
    logger.warn('table-ws has rejected the user token');
});

export const usePureProxyClient = () => {
    return client;
};

const exportedClient: RawClient = client;

export { exportedClient as client };

export const setAccessToken = (accessToken: string) => {
    logger.info('About to log in using token', accessToken);
    client.token = accessToken;
};

export const createTable = async (payload: CreateTablePayload): Promise<TableCreationResult> => {
    return (await axios.post(`/tables`, payload)).data;
};
