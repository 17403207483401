import { rootReducer } from './reducers';
import { rootSaga } from './sagas';
import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, createStore, Store as StoreType } from 'redux';
import createSagaMiddleware from 'redux-saga';

export type RootState = ReturnType<typeof rootReducer>;
export type RootInstance = StoreType<RootState>;

export class Store {
    #store: StoreType<RootState>;
    #sagaMiddleware: ReturnType<typeof createSagaMiddleware>;

    constructor() {
        this.#sagaMiddleware = createSagaMiddleware();

        const middlewares = [this.#sagaMiddleware];
        const middlewareEnhancer = applyMiddleware(...middlewares);

        const enhancers = [middlewareEnhancer];

        const composedEnhancers = composeWithDevTools(...enhancers);

        this.#store = createStore(rootReducer, {}, composedEnhancers);
    }

    get instance() {
        return this.#store;
    }

    run() {
        this.#sagaMiddleware.run(rootSaga);
    }
}

export const store = new Store();
