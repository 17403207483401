import { ResearchStatus } from '../../types';

export type SetFormulasForQueriesAction = {
    type: 'setFormulasForQueries';
    formulasByQuery: Record<string, string>;
};

export const setFormulasForQueries = (formulasByQuery: Record<string, string>): SetFormulasForQueriesAction => ({
    type: 'setFormulasForQueries',
    formulasByQuery,
});

export type SetNaturalLanguageStatusAction = {
    type: 'setNaturalLanguageStatus';
    query: string;
    status: ResearchStatus;
};
export const setNaturalLanguageStatus = (query: string, status: ResearchStatus): SetNaturalLanguageStatusAction => ({
    query,
    status,
    type: 'setNaturalLanguageStatus',
});

export type ResearchNaturalLanguageAction = { type: 'researchNaturalLanguage'; query: string };
export const researchNaturalLanguage = (query: string): ResearchNaturalLanguageAction => {
    return {
        query,
        type: 'researchNaturalLanguage',
    };
};

export type SetSuggestionsForQuery = { type: 'setSuggestionsForQuery'; query: string; suggestions: string[] };
export const setSuggestions = (query: string, suggestions: string[]): SetSuggestionsForQuery => ({
    query,
    suggestions,
    type: 'setSuggestionsForQuery',
});

export type SetCommonSuggestions = { type: 'setCommonSuggestions'; suggestions: string[] };

export const setCommonSuggestions = (suggestions: string[]): SetCommonSuggestions => ({
    suggestions,
    type: 'setCommonSuggestions',
});

export type QueryAction =
    | SetFormulasForQueriesAction
    | SetNaturalLanguageStatusAction
    | ResearchNaturalLanguageAction
    | SetSuggestionsForQuery
    | SetCommonSuggestions;
