import { UniverseRecord } from '../../web/src/contracts/universe';
import { useEvent } from '../../web/src/hooks/useEvent';
import { useSelector } from './hooks';
import { Button, ButtonGroup, Menu, MenuItem } from '@mui/material';
import React, { FC, useMemo } from 'react';

function ButtonContent({ children, isActive }) {
    return (
        <span className={`border-b-2 border-solid text-stone-500 ${isActive ? 'border-blue-500' : 'border-stone-500'}`}>
            {children}
        </span>
    );
}

function UniverseButton({ value, children, onClick, ...props }) {
    const handleClick = useEvent((e) => {
        onClick(e, value);
    });
    return (
        <Button {...props} onClick={handleClick} className="border-stone-200">
            {children}
        </Button>
    );
}

function UniverseMenuItem({ value, children, onClick, ...props }) {
    const handleClick = useEvent((e) => {
        onClick(e, value);
    });
    return (
        <MenuItem {...props} onClick={handleClick}>
            {children}
        </MenuItem>
    );
}

interface UniverseSelectProps {
    selectedUniverse: UniverseRecord | undefined;
    setUniverse: (universe: UniverseRecord | undefined) => void;
}

export const UniverseSelect: FC<UniverseSelectProps> = ({ setUniverse, selectedUniverse }) => {
    const universes = useSelector((state) => state.app.visibleUniverses);
    const defaultUniverses = useSelector((app) => app.app.defaultUniverseList);

    const handleSelectDefaultUniverse = useEvent((_e, newValue: string) => {
        const selected = universes?.find((universe) => universe.id === newValue);
        if (selected) {
            setUniverse(selected);
        }
    });

    const notDefaultUniverses = useMemo(() => {
        return universes?.filter(({ id }) => !defaultUniverses.some((item) => item.id === id));
    }, [universes, defaultUniverses]);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const showMenu = useEvent((event) => {
        setAnchorEl(event.currentTarget);
    });

    const closeMenu = useEvent(() => {
        setAnchorEl(null);
    });

    const handleSelectUniverse = useEvent((_e, universe: UniverseRecord) => {
        setUniverse(universe);
        setAnchorEl(null);
    });

    const isSelectedInDefault = useMemo(() => {
        return defaultUniverses.some((item) => item.id === selectedUniverse?.id);
    }, [selectedUniverse, defaultUniverses]);

    return (
        <>
            <ButtonGroup size="small" variant="text">
                {defaultUniverses.map((item) => (
                    <UniverseButton
                        key={item.id}
                        value={item.id}
                        disableRipple
                        size="small"
                        onClick={handleSelectDefaultUniverse}
                    >
                        <ButtonContent isActive={item.id === selectedUniverse?.id}>{item.displayName}</ButtonContent>
                    </UniverseButton>
                ))}
                {!isSelectedInDefault && selectedUniverse && (
                    <Button disableRipple size="small" className="border-stone-200">
                        <ButtonContent isActive={true}>{selectedUniverse?.name}</ButtonContent>
                    </Button>
                )}
                <Button
                    className="text-xs text-stone-500"
                    aria-controls="universe-select-menu"
                    aria-haspopup="true"
                    onClick={showMenu}
                >
                    <i className="fas fa-caret-down" />
                </Button>
            </ButtonGroup>
            <Menu
                id="universe-select-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
            >
                {notDefaultUniverses?.map((universe) => {
                    return (
                        <UniverseMenuItem
                            key={universe.id}
                            value={universe}
                            onClick={handleSelectUniverse}
                            className="hover:bg-stone-100"
                        >
                            {universe.name}
                        </UniverseMenuItem>
                    );
                })}
            </Menu>
        </>
    );
};
