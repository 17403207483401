import { useSelector } from '../../hooks';
import { AppAccessStatus } from '../../types';
import React from 'react';
import { Navigate } from 'react-router-dom';

interface AuthBlockerProps {
    children: JSX.Element;
}

export const AuthBlocker: React.FC<AuthBlockerProps> = ({ children }) => {
    const user = useSelector((state) => state.auth.user);
    const appStatus = useSelector((state) => state.app.appStatus);

    const canAccess =
        appStatus === AppAccessStatus.initializedForPublicUse ||
        (appStatus === AppAccessStatus.initializedForPrivateUse && user);

    if (canAccess) {
        return children;
    } else {
        return <Navigate to="/login" />;
    }
};
