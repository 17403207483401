import { Column, columnDisplayNames, Row } from '../../types';
import { formatRow } from './formatRow';
import { RowStateManager } from './useRowStateManager';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
} from '@mui/material';
import { SortOrder } from '@thinkalpha/table-client';
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

function getNumberClassticker(value: string) {
    const number = parseFloat(value.endsWith('%') ? value.slice(0, -1) : value);

    return Math.sign(number) === 1 ? 'text-green-600' : Math.sign(number) === -1 ? 'text-red-600' : '';
}

function renderData(row: Record<keyof Row, string>) {
    const { rowId: _, name, ticker, last, change, percentageChange, marketCap, volume, ...rest } = row;

    const data: Partial<Record<keyof Row, string | JSX.Element>> = {
        name: (
            <Tooltip className="cursor-default" classes={{ tooltip: 'max-w-lg' }} title={name} placement="top" arrow>
                <div className="truncate" style={{ width: '150px' }}>
                    {name}
                </div>
            </Tooltip>
        ),
        ticker,
        last: (
            <span className="inline-block" style={{ width: '75px' }}>
                {last}
            </span>
        ),
        change: (
            <span style={{ width: '75px' }} className={`inline-block ${getNumberClassticker(change)}`}>
                {change}
            </span>
        ),
        percentageChange: (
            <span style={{ width: '75px' }} className={`inline-block ${getNumberClassticker(percentageChange)}`}>
                {percentageChange}
            </span>
        ),
        marketCap: (
            <span style={{ width: '125px' }} className="inline-block">
                {marketCap}
            </span>
        ),
        volume: (
            <span style={{ width: '75px' }} className="inline-block">
                {volume}
            </span>
        ),
    };

    for (const validationCell in rest) {
        data[validationCell] = (
            <span style={{ width: '75px' }} className="inline-block">
                {rest[validationCell]}
            </span>
        );
    }

    return data;
}

function formatColumnHeaderName(headerName: string, validationColumnNameLookup: Map<string, string> | undefined) {
    // If the header exists in the validationColumnNameLookup, it is a validation mode column
    if (validationColumnNameLookup) {
        const validationColumnName = validationColumnNameLookup.get(headerName);
        if (validationColumnName) return validationColumnName;
    }

    return columnDisplayNames.get(headerName as Column)!;
}

interface EnhancedTableProps {
    columns: string[];
    sortBy: string;
    sortOrder: SortOrder;
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    validationColumnNameLookup: Map<string, string> | undefined;
}

function EnhancedTableHead({
    columns,
    sortBy,
    sortOrder,
    onRequestSort,
    validationColumnNameLookup,
}: EnhancedTableProps) {
    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell
                        key={headCell}
                        align={headCell === Column.name ? 'center' : 'left'}
                        classes={{
                            root: 'bg-stone-100 text-stone-900 border-stone-300',
                        }}
                    >
                        <TableSortLabel
                            active={sortBy === headCell}
                            direction={
                                sortBy === headCell ? (sortOrder === SortOrder.ascending ? 'asc' : 'desc') : undefined
                            }
                            onClick={createSortHandler(headCell)}
                            classes={{
                                root: 'text-stone-900',
                                icon: 'text-stone-900',
                            }}
                        >
                            {formatColumnHeaderName(headCell, validationColumnNameLookup)}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface ResultsTableProps {
    rowStateManager: InstanceType<typeof RowStateManager>;
    rowCount: number;
    sortBy: Column;
    setSortBy: Dispatch<SetStateAction<Column>>;
    sortOrder: SortOrder;
    setSortOrder: Dispatch<SetStateAction<SortOrder>>;
    tableRef: React.RefObject<HTMLTableElement>;
    validationColumnNameLookup: Map<string, string> | undefined;
}

const ResultRow = ({
    rowIndex,
    rowStateManager,
}: {
    rowIndex: number;
    rowStateManager: InstanceType<typeof RowStateManager>;
}) => {
    const [row, setRow] = useState<Row | undefined>(undefined);

    useEffect(() => {
        const unsubscribe = rowStateManager.subscribe(rowIndex, (row) => {
            setRow(row);
        });

        return unsubscribe;
    }, [rowIndex, setRow, rowStateManager]);

    if (!row) return null;

    const data = renderData(formatRow(row as any) as any);

    return (
        <TableRow>
            {Object.entries(data).map(([key, value]) => (
                <TableCell
                    key={key}
                    align="left"
                    classes={{
                        root: 'border-stone-300',
                        body: 'text-stone-900',
                    }}
                >
                    {value}
                </TableCell>
            ))}
        </TableRow>
    );
};

export const ResultsTable: FC<ResultsTableProps> = ({
    rowStateManager,
    rowCount,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    tableRef,
    validationColumnNameLookup,
}) => {
    const handleRequestSort = useCallback(
        (_event: React.MouseEvent<unknown>, property: string) => {
            const isAsc = sortBy === property && sortOrder === SortOrder.ascending;
            setSortOrder(isAsc ? SortOrder.descending : SortOrder.ascending);
            setSortBy(property as Column);
        },
        [sortBy, sortOrder, setSortBy, setSortOrder],
    );

    const rowIndices = useMemo(() => {
        return Array(rowCount)
            .fill(0)
            .map((_, index) => index);
    }, [rowCount]);

    const columns = rowStateManager.getColumns();

    return (
        <TableContainer>
            <Table stickyHeader className="min-w-[60rem] lg:min-w-[50rem] xl:w-full xl:min-w-0" ref={tableRef}>
                <EnhancedTableHead
                    columns={columns}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    onRequestSort={handleRequestSort}
                    validationColumnNameLookup={validationColumnNameLookup}
                />
                <TableBody>
                    {rowIndices.map((rowIndex) => {
                        return <ResultRow key={rowIndex} rowIndex={rowIndex} rowStateManager={rowStateManager} />;
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
