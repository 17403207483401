import { Column, columnDisplayNames, Row } from '../../types';
import { formatRow } from './formatRow';
import { unparse } from 'papaparse';

export const createCSV = (rows: Row[]): string => {
    const values = rows.map(formatRow).map((row) => {
        return Object.fromEntries(
            Object.entries(row).map(([key, value]) => {
                return [columnDisplayNames.get(key as Column), value];
            }),
        );
    });

    const csv = unparse(values);

    return csv;
};

export const copyToClipboardCSV = (rows: Row[]) => {
    const csv = createCSV(rows);

    try {
        navigator.clipboard.writeText(csv);
        return true;
    } catch (err) {
        console.error(err);
        return err;
    }
};
