import type { Row } from '../../types';
import { lowerCase, startCase, toUpper } from 'lodash';
import numbro from 'numbro';

function formatName(name: string) {
    return startCase(lowerCase(name));
}

function formatLast(value: number) {
    const rounded = Math.round(value * 100) / 100;
    return rounded.toFixed(2);
}

function formatMarketCap(value: number) {
    return toUpper(numbro(value).format({ mantissa: 2, average: true }));
}

function formatVolume(value: number) {
    return toUpper(numbro(value).format({ mantissa: 2, average: true }));
}

function formatChange(value: number) {
    const rounded = Math.round(value * 100) / 100;
    return rounded.toFixed(2);
}

function formatPercentageChange(value: number) {
    const asPercent = Math.round(value * 10000) / 100;
    return `${asPercent.toFixed(2)}%`;
}

function formatValidationData(value: any) {
    if (typeof value === 'number') {
        return validateNumber(value, () => {
            const rounded = Math.round(value * 100) / 100;
            return rounded.toFixed(2);
        });
    } else {
        return value.toString();
    }
}

function validateNumber(value: number, formatter: (value: number) => string) {
    if (isNaN(value)) return '';
    return formatter(value);
}

export function formatRow(unformattedRow: Row) {
    const { rowId: _, name, ticker, last, change, percentageChange, marketCap, volume, ...rest } = unformattedRow;
    const row: Partial<Record<keyof Row, any>> = {
        name: formatName(name),
        ticker,
        last: validateNumber(last, formatLast),
        change: validateNumber(change, formatChange),
        percentageChange: validateNumber(percentageChange, formatPercentageChange),
        marketCap: validateNumber(marketCap, formatMarketCap),
        volume: validateNumber(volume, formatVolume),
    };

    for (const validationCell in rest) {
        row[validationCell] = formatValidationData(rest[validationCell]);
    }

    return row;
}
