import { Header } from './header';
import React, { PropsWithChildren } from 'react';

interface LayoutProps {
    containerClassName?: string;
}

export const MainLayout: React.FC<PropsWithChildren<LayoutProps>> = ({ children, containerClassName }) => {
    return (
        <div className="mx-auto max-w-[90%]">
            <Header />
            <div className={`py-4  ${containerClassName}`}>{children}</div>
        </div>
    );
};
