import { ConcreteColumnTemplate } from '../../../../web/src/contracts/column-template';
import { UniverseRecord } from '../../../../web/src/contracts/universe';
import { AppAccessStatus, DefaultUniverseList } from '../../types';
import { AppAction } from '../actions';

interface AskAlphaState {
    bootstrapped: boolean;
    appStatus: AppAccessStatus;
    visibleUniverses: UniverseRecord[];
    defaultUniverse: UniverseRecord | null;
    defaultUniverseList: DefaultUniverseList;
    defaultColumnTemplate: ConcreteColumnTemplate | null;
    defaultColumnTemplateMapping: ColumnTemplateMapping | null;
}

interface ColumnTemplateMapping {
    nameToId: Record<string, string>;
    idToName: Record<string, string>;
}

export const initialState: AskAlphaState = {
    bootstrapped: false,
    appStatus: AppAccessStatus.uninitialized,
    visibleUniverses: [],
    defaultUniverse: null,
    defaultUniverseList: [],
    defaultColumnTemplate: null,
    defaultColumnTemplateMapping: null,
};

export const app = (state = initialState, action: AppAction): AskAlphaState => {
    switch (action.type) {
        case 'app-access-status::set': {
            return { ...state, appStatus: action.appStatus };
        }

        case 'bootstrap::begin': {
            return { ...state, bootstrapped: false };
        }

        case 'bootstrap::complete': {
            return { ...state, bootstrapped: true };
        }

        case 'default-universe-list::set': {
            return {
                ...state,
                defaultUniverseList: action.defaultUniverseList,
            };
        }

        case 'universes::setup': {
            return { ...state, visibleUniverses: action.visibleUniverses, defaultUniverse: action.defaultUniverse };
        }

        case 'colTemplates::setup': {
            let defaultColumnTemplateMapping: ColumnTemplateMapping | null = null;

            if (action.defaultColumnTemplate) {
                defaultColumnTemplateMapping = {
                    nameToId: action.defaultColumnTemplate.columns.reduce(
                        (acc, col) => {
                            acc[col.name] = col.id;
                            return acc;
                        },
                        { ticker: 'ticker' },
                    ),
                    idToName: action.defaultColumnTemplate.columns.reduce(
                        (acc, col) => {
                            acc[col.id] = col.name;
                            return acc;
                        },
                        { ticker: 'ticker' },
                    ),
                };
            }

            return {
                ...state,
                defaultColumnTemplate: action.defaultColumnTemplate,
                defaultColumnTemplateMapping,
            };
        }
        default:
            return state;
    }
};
