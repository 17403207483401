import { BaseResource, BaseVersion, ConcreteResource, VersionedResource } from './base-resource';
import { ColumnTemplate, ConcreteColumnTemplate } from './column-template';
import { IndicatorFormulaViewModel, IndicatorImportRefViewModel } from './dictionary-view-model';
import { IfThenResearchPlan } from './if-then';
import { AppliedPermissions, Permissioned } from './permissions';
import { TableCreationResult } from './table';
import { Universe } from './universe';
import { Instant as Date } from '@js-joda/core';

export enum StrategyType {
    formula = 'formula',
}

export interface StrategyInstance extends TableCreationResult {
    columnTemplate: ConcreteColumnTemplate;
    columns: {
        symbol: string;
    };
}

export interface StrategyBase extends BaseResource {
    type: StrategyType;
    name: string | null;
    description: string | null;
    // If defaultUniverseId is null, strategy is run with default universe from user-preferences
    // This property is set when a non-default universe is selected and saved to strategy
    defaultUniverseId: string | null;
    defaultColumnTemplateId: string | null;
}

export type IfThenStrategyPlan<T extends IndicatorImportRefViewModel = IndicatorImportRefViewModel> =
    | IfThenResearchPlan<'ticker', T>
    | IndicatorFormulaViewModel<T>;

export interface IfThenStrategy<
    T extends IndicatorImportRefViewModel = IndicatorImportRefViewModel,
    P extends IfThenStrategyPlan<T> = IfThenStrategyPlan<T>,
> extends StrategyBase {
    type: StrategyType.formula;
    plan: P;
}

export type Strategy<T extends IndicatorImportRefViewModel = IndicatorImportRefViewModel> = IfThenStrategy<T>;

export interface ConcreteStrategy<T extends IndicatorImportRefViewModel = IndicatorImportRefViewModel>
    extends Strategy<T>,
        ConcreteResource,
        Permissioned,
        BaseVersion {
    id: string;
}

export interface StrategyVersion
    extends Omit<ConcreteStrategy, 'id' | 'permissions' | 'createdAt' | 'isTemplate'>,
        BaseVersion {}
export interface StrategyWithVersions extends VersionedResource<ConcreteStrategy, StrategyVersion> {}

export type NewStrategy = Omit<Strategy, 'createdAt' | 'discardedAt' | 'isTemplate'>;
export interface StrategyPatch extends Partial<Pick<Strategy, 'plan' | 'defaultUniverseId' | 'name' | 'description'>> {}

export interface NewStrategyInstance {
    strategies: (Strategy | string)[];
    universe: string | Universe | null;
    columnTemplate: string | ColumnTemplate | null;
    scrolling?: boolean;
}

export interface StrategyRecord {
    id: string;
    name?: string;
    description?: string;
    type: StrategyType;
    permissions: AppliedPermissions;
    createdAt: Date;
    isTemplate: boolean;
}

export interface TopStrategies {
    recentlyCreated: StrategyRecord[];
    recentlyAccessed: StrategyRecord[];
}
