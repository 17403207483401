/**
 * Boolean set to either `"true"` or `"false"` string which enables
 * superuser permission for the server-side access considerations
 * as part of the API request.
 *
 * @note previously known as `x-admin`
 */
export const enableRemoteSuperuserHeader = 'x-superuser' as const;

/**
 * Header containing UUID of the request chain. Usually this is set by
 * the entry service and is used to track request flow between services
 * but the external clients can set this as well.
 *
 * This header appears on responses.
 */
export const requestIdHeader = 'x-request-id' as const;
