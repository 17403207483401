import { Dictionary } from './objectDictionary';
import { ChronoUnit, Duration, Instant, IsoFields, TemporalUnit, ZoneOffset } from '@js-joda/core';
import { Anchor } from '@thinkalpha/language-services';

export {
    isAbsolutePointInTime,
    isBarCountPointInTime,
    isPointInTime,
    isRelativePointInTime,
    isTimeframe,
} from '@thinkalpha/language-services';
export type {
    AbsolutePointInTime,
    Anchor,
    BarLength,
    CountPointInTime,
    PointInTime,
    RelativePointInTime,
    SlidingWindow,
    Timeframe,
} from '@thinkalpha/language-services';

type FullPeriodType = 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year';

export enum PeriodType {
    second = 's',
    minute = 'm',
    hour = 'h',
    day = 'd',
    week = 'W',
    month = 'Mo',
    quarter = 'Q',
    year = 'Y',
}

const PeriodTypeDict: Dictionary<PeriodType> = PeriodType;
export const PeriodNames: readonly FullPeriodType[] = Object.keys(PeriodType) as FullPeriodType[];
Object.freeze(PeriodNames);
export const PeriodNameMap: ReadonlyMap<PeriodType, FullPeriodType> = new Map<PeriodType, FullPeriodType>(
    PeriodNames.map((name) => [PeriodTypeDict[name], name]),
);
Object.freeze(PeriodNameMap);
export const PeriodNameToTemporalUnitMap: ReadonlyMap<PeriodType, TemporalUnit> = new Map<PeriodType, TemporalUnit>([
    [PeriodType.day, ChronoUnit.DAYS],
    [PeriodType.hour, ChronoUnit.HOURS],
    [PeriodType.minute, ChronoUnit.MINUTES],
    [PeriodType.month, ChronoUnit.MONTHS],
    [PeriodType.quarter, IsoFields.QUARTER_YEARS],
    [PeriodType.second, ChronoUnit.SECONDS],
    [PeriodType.week, ChronoUnit.WEEKS],
    [PeriodType.year, ChronoUnit.YEARS],
]);
Object.freeze(PeriodNameToTemporalUnitMap);
export const PeriodTypes: readonly PeriodType[] = PeriodNames.map((x) => PeriodTypeDict[x] as PeriodType);
Object.freeze(PeriodTypes);

export const setAnchorOnInstant = (instant: Instant, anchor: Anchor) => {
    let zonedDateTime = instant.atZone(ZoneOffset.UTC);

    if (anchor.year) zonedDateTime = zonedDateTime.withYear(anchor.year);
    if (anchor.month) zonedDateTime = zonedDateTime.withMonth(anchor.month);
    if (anchor.day) zonedDateTime = zonedDateTime.withDayOfMonth(anchor.day);
    if (anchor.hour) zonedDateTime = zonedDateTime.withHour(anchor.hour);
    if (anchor.minute) zonedDateTime = zonedDateTime.withMinute(anchor.minute);
    if (anchor.second) zonedDateTime = zonedDateTime.withSecond(anchor.second);

    return Instant.from(zonedDateTime);
};

export enum HistoryType {
    realtime = 'realtime',
    lookback = 'lookback',
    static = 'static',
    dynamic = 'dynamic',
    count = 'count',
}

export interface AbsoluteInterval {
    start: Instant;
    end: Instant;
}
export interface TailingInterval {
    start: Duration;
    end: null;
}

export type Interval = AbsoluteInterval | TailingInterval;

export function isTailingInterval(interval: Interval): interval is TailingInterval {
    return interval.end === null;
}
export function isAbsoluteInterval(interval: Interval): interval is AbsoluteInterval {
    return interval.end !== null;
}
export type SessionType = 'all' | 'market' | 'extended';
