import { LogoLink } from '../../LogoLink';
import { QueryField } from '../QueryField';
import { MainLayout } from '../main-layout';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const StartPage = () => {
    const [query, setQuery] = useState<string>('');
    const navigate = useNavigate();

    const onSubmit = useCallback(
        (queryText: string) => {
            navigate(`/results?query=${encodeURIComponent(queryText)}`);
        },
        [navigate],
    );

    return (
        <MainLayout containerClassName="flex flex-col items-center">
            <LogoLink />
            <QueryField value={query} setValue={setQuery} onSubmit={onSubmit} />
        </MainLayout>
    );
};
