import { Spinner } from '../../../../web/src/components/spinner/spinner';
import { StrategyInstance } from '../../../../web/src/contracts/strategy';
import { useEvent } from '../../../../web/src/hooks/useEvent';
import { pendingState, startingState } from '../../App';
import { IconLink } from '../../IconLink';
import { LogoLink } from '../../LogoLink';
import { useSelector } from '../../hooks';
import { researchNaturalLanguage } from '../../store/actions/queries';
import { QueryField } from '../QueryField';
import { MainLayout } from '../main-layout';
import { InnerResultsPage } from './InnerResultsPage';
import { getRunInfoFromNLP } from './getRunInfo';
import { InputAdornment, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const ResultsPage: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const currentActiveQuery = searchParams.get('query') ?? '';
    const [query, setQuery] = useState(currentActiveQuery ?? '');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const formula = useSelector((state) => state.queries.formulasByQuery[currentActiveQuery]);
    const defaultUniverse = useSelector((state) => state.app.defaultUniverse);
    const defaultColumnTemplate = useSelector((state) => state.app.defaultColumnTemplate);

    const [activeUniverse, setActiveUniverse] = useState(defaultUniverse);

    const universeToUse = activeUniverse ?? defaultUniverse;

    const [runInfo, setRunInfo] = useState<StrategyInstance | undefined | typeof startingState | typeof pendingState>(
        startingState,
    );
    const [validationColumnNameLookup, setValidationColumnNameLookup] = useState<Map<string, string> | undefined>();

    useEffect(() => {
        dispatch(researchNaturalLanguage(currentActiveQuery));
    }, [currentActiveQuery, dispatch]);

    useEffect(() => {
        if (!currentActiveQuery) {
            navigate('/');
        } else {
            if (universeToUse && defaultColumnTemplate) {
                setRunInfo(pendingState);

                getRunInfoFromNLP(currentActiveQuery, defaultColumnTemplate.id, universeToUse.id).then(
                    ({ strategyInstance, validationColumnNameLookup }) => {
                        setRunInfo(strategyInstance);
                        setValidationColumnNameLookup(validationColumnNameLookup);
                    },
                );
            }
        }
    }, [navigate, currentActiveQuery, universeToUse, setRunInfo, defaultColumnTemplate]);

    const onSubmit = useEvent((query: string) => {
        // Copy all params over, so we can migrate these to be very shareable (universes, etc shared via URL)
        const newParams = { ...Object.fromEntries(searchParams.entries()), query };
        setSearchParams(newParams);
    });

    const inputEndAdornment = formula ? (
        <InputAdornment position="end" className="mr-1">
            <Tooltip
                className="cursor-default"
                classes={{ tooltip: 'max-w-lg' }}
                title={formula}
                placement="top"
                leaveDelay={100}
                arrow
            >
                <i className="far fa-function text-stone-500 dark:text-stone-400" />
            </Tooltip>
        </InputAdornment>
    ) : undefined;

    const queryField = (
        <QueryField value={query} setValue={setQuery} inputEndAdornment={inputEndAdornment} onSubmit={onSubmit} />
    );

    return (
        <MainLayout>
            {/* Replicate the home page look on the small screens */}
            <div className="flex flex-col items-center sm:hidden">
                <LogoLink />
                {queryField}
            </div>

            <div className="hidden flex-row items-center sm:flex">
                <IconLink />
                <div className="w-5/6 sm:ml-4">
                    {queryField}
                    {currentActiveQuery && (
                        <div className="xs:mt-6 mt-4 text-xs text-stone-500">Results for: {currentActiveQuery}</div>
                    )}
                </div>
            </div>
            {!currentActiveQuery || !universeToUse || runInfo === startingState || runInfo === pendingState ? (
                <Spinner centered className="mt-12" />
            ) : !formula || !runInfo ? (
                <div className="mt-12 flex justify-center">That formula could not be parsed. Please try again!</div>
            ) : (
                <InnerResultsPage
                    setUniverse={setActiveUniverse}
                    universe={universeToUse}
                    runInfo={runInfo}
                    validationColumnNameLookup={validationColumnNameLookup}
                />
            )}
        </MainLayout>
    );
};
