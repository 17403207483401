import { setStoreForApi } from '../../web/src/api';
import { AuthBlocker } from './components/auth-blocker';
import { LoginPage } from './components/login-page';
import { ResultsPage } from './components/results-page/ResultsPage';
import { StartPage } from './components/start-page';
import { useSelector } from './hooks';
import { store } from './store';
import { AppAccessStatus } from './types';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';

export const startingState = Symbol('startingState');
export const pendingState = Symbol('pendingState');

const AppContent: React.FC = () => {
    return (
        <Routes>
            <Route path="/results" element={<ResultsPage />} />
            <Route path="" element={<StartPage />} />
        </Routes>
    );
};

export const AppInitializer = () => {
    const appStatus = useSelector((state) => state.app.appStatus);
    const user = useSelector((state) => state.auth.user);

    const navigate = useNavigate();

    useEffect(() => {
        store.run();

        setStoreForApi(store.instance);
    }, []);

    useEffect(() => {
        if (appStatus === AppAccessStatus.initializedForPrivateUse && !user) {
            navigate('/login');
        }
    }, [appStatus, user, navigate]);

    if (appStatus === AppAccessStatus.uninitialized) {
        return null;
    }

    return (
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route
                path="*"
                element={
                    <AuthBlocker>
                        <AppContent />
                    </AuthBlocker>
                }
            />
        </Routes>
    );
};

export const App = () => {
    return (
        <Provider store={store.instance}>
            <BrowserRouter>
                <AppInitializer />
            </BrowserRouter>
        </Provider>
    );
};

export default App;
