import axios, { rxapi } from '../api';
import { processQuery } from './paging';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    ColumnTemplate,
    ColumnTemplatePatch,
    ColumnTemplateRecord,
    ConcreteColumnTemplate,
} from 'src/contracts/column-template';
import { ResourceQuery, ResourceQueryResponseWithMeta } from 'src/contracts/resource-query';

export const getColumnTemplatesQuery = async (
    query: ResourceQuery,
): Promise<ResourceQueryResponseWithMeta<ConcreteColumnTemplate>> => {
    return (await axios.get(`/column-templates/query${processQuery(query)}`)).data;
};

export function getColumnTemplateRecords(key = 'ticker'): Observable<ColumnTemplateRecord[]> {
    return rxapi.get<ColumnTemplateRecord[]>('/column-templates', { params: { key } }).pipe(map((x) => x.data));
}

export function getDefaultColumnTemplate(key: string): Observable<ConcreteColumnTemplate> {
    return rxapi.get<ConcreteColumnTemplate>(`/column-templates/default`, { params: { key } }).pipe(map((x) => x.data));
}

export function getColumnTemplate(id: string): Observable<ConcreteColumnTemplate> {
    return rxapi.get<ConcreteColumnTemplate>(`/column-templates/${id}`).pipe(map((x) => x.data));
}

export function createColumnTemplate(columnTemplate: ColumnTemplate): Observable<ConcreteColumnTemplate> {
    return rxapi.post<ConcreteColumnTemplate>('/column-templates', columnTemplate).pipe(map((x) => x.data));
}

export function updateColumnTemplate(id: string, patch: ColumnTemplatePatch): Observable<ConcreteColumnTemplate> {
    return rxapi.patch<ConcreteColumnTemplate>(`/column-templates/${id}`, patch).pipe(map((x) => x.data));
}

export async function deleteColumnTemplate(id: string): Promise<void> {
    return (await axios.delete(`/column-templates/${id}`)).data;
}
