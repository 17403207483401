import api from '../../../../web/src/api';
import appConfig from '../../../../web/src/config';
import { ColumnTemplateRecord } from '../../../../web/src/contracts/column-template';
import { UniverseRecord } from '../../../../web/src/contracts/universe';
import { getColumnTemplate, getColumnTemplateRecords } from '../../../../web/src/services/column-templates';
import { commonNlpExamples$ } from '../../../../web/src/services/nlp';
import { getUniverses } from '../../../../web/src/services/universes';
import { GlobalSettings } from '../../contracts/settings';
import { getGlobalSettings } from '../../services/settings';
import { AppAccessStatus, NlpSuggestion, UserDetails } from '../../types';
import {
    setAccessToken,
    SetAccessTokenAction,
    setAppAccessStatus,
    setDefaultUniverseList,
    setupColumnTemplate,
    setupUniverses,
    setUserDetails,
} from '../actions';
import { setCommonSuggestions } from '../actions/queries';
import { Instant } from '@js-joda/core';
import jd, { JwtPayload } from 'jwt-decode';
import { all, call, put, takeLatest } from 'redux-saga/effects';
// TODO: findout why linter barks on the next line in precommit hook
// eslint-disable-next-line import/no-unresolved
import { firstValueFrom } from 'rxjs';

/*
User has token? Always use that.
User has no token / token has invalidated?
If anonymous access is true and a public access token is not set, sorry; gotta login
If anonymous access is true and a public access token is set, you can use that
If anonymous access is false, sorry; gotta login
*/

const ACCESS_TOKEN_KEY = 'askalpha__access__token';

function* bootstrapAppSaga() {
    // Step 1, pull global settings
    const globalSettings: GlobalSettings = yield call(getGlobalSettings);
    const askAlphaSettings = globalSettings.askAlpha;
    const { publicAccessToken, anonymousAccessEnabled, defaultUniverseList } = askAlphaSettings;

    if (defaultUniverseList && defaultUniverseList.length) {
        yield put(setDefaultUniverseList(defaultUniverseList));
    }

    const canUseAnonymously = anonymousAccessEnabled && publicAccessToken;

    let storedToken = localStorage.getItem(ACCESS_TOKEN_KEY);

    if (storedToken) {
        const exp = getExpirationDate(storedToken);

        if (!exp || exp.isBefore(Instant.now())) {
            localStorage.removeItem(ACCESS_TOKEN_KEY);
            storedToken = null;
        } else {
            yield put(setAccessToken(storedToken));

            try {
                const url = `${appConfig.auth}/users/me`;

                const userResponse: { data: UserDetails } | undefined = yield call(api.get, url, {
                    headers: { Authorization: `Bearer ${storedToken}` },
                });

                if (userResponse?.data) {
                    const userDetails = userResponse.data;
                    // put user response set
                    yield put(setUserDetails(userDetails));
                    yield put(
                        setAppAccessStatus(
                            canUseAnonymously
                                ? AppAccessStatus.initializedForPublicUse
                                : AppAccessStatus.initializedForPrivateUse,
                        ),
                    );
                } else {
                    yield put(setUserDetails(undefined));
                }
            } catch (e) {
                yield put(setUserDetails(undefined));
            }
        }
    }

    if (!storedToken) {
        if (anonymousAccessEnabled && publicAccessToken) {
            yield put(setAccessToken(publicAccessToken));
            yield put(setAppAccessStatus(AppAccessStatus.initializedForPrivateUse));
        } else {
            yield put(setAppAccessStatus(AppAccessStatus.initializedForPrivateUse));
        }
    }
}

function* onSetAccessToken(action: SetAccessTokenAction) {
    if (action.token) {
        if (!action.isInitial) {
            localStorage.setItem(ACCESS_TOKEN_KEY, action.token);
        }
        yield call(loadInitialData);
    } else {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
    }
}

function* onClearAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
}

function* loadDefaultSuggestions() {
    const defaultSuggestions: { data?: NlpSuggestion[] } = yield call(firstValueFrom, commonNlpExamples$);
    if (defaultSuggestions.data) {
        yield put(
            setCommonSuggestions(defaultSuggestions.data.filter((x) => x.type === 'conditions').map((x) => x.input)),
        );
    }
}

function* loadUniverses() {
    const universes: UniverseRecord[] = yield call(firstValueFrom, getUniverses());

    const visibleUniverses = universes.filter((x) => x.premade);
    const defaultUniverse = universes.find((x) => x.default);

    if (!defaultUniverse) {
        throw new Error('Could not get default universe');
    }

    yield put(setupUniverses(visibleUniverses, defaultUniverse));
}

function* loadColumnTemplates() {
    const columnTemplates: ColumnTemplateRecord[] = yield call(firstValueFrom, getColumnTemplateRecords('ticker'));

    const defaultColTemplate = columnTemplates.find((x) => x.default);

    if (!defaultColTemplate) {
        throw new Error('Could not get default column template');
    }

    const columnTemplate = yield call(firstValueFrom, getColumnTemplate(defaultColTemplate.id));

    yield put(setupColumnTemplate(columnTemplate));
}

function* loadInitialData() {
    yield all([call(loadDefaultSuggestions), call(loadUniverses), call(loadColumnTemplates)]);
}

export function* appSagas() {
    yield all([
        bootstrapAppSaga(),
        takeLatest('access-token::set', onSetAccessToken),
        takeLatest('access-token::clear', onClearAccessToken),
        takeLatest('logout', onClearAccessToken),
    ]);
}

const getExpirationDate = (token: string): Instant | undefined => {
    const payload = jd<JwtPayload>(token);
    if (typeof payload.exp === 'number' && typeof payload.iat === 'number') {
        return Instant.ofEpochSecond(payload.exp);
    }
    return undefined;
};
