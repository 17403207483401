/**
 * A hash based value-equivalence map.
 */
export class HashMap<TKey, TValue> implements Map<TKey, TValue> {
    private map: Map<string, Set<{ key: TKey; value: TValue }>> = new Map();
    constructor(
        private hash: (key: TKey) => string,
        private isEqual: (a: TKey, b: TKey) => boolean,
        initial?: Iterable<readonly [TKey, TValue]>,
    ) {
        if (initial) {
            for (const [key, value] of initial) {
                this.set(key, value);
            }
        }
    }
    clear(): void {
        this.map.clear();
    }
    delete(key: TKey): boolean {
        const hash = this.hash(key);
        const options = this.map.get(hash);
        if (!options) return false;
        let found = false;
        for (const option of options) {
            if (this.isEqual(option.key, key)) {
                options.delete(option);
                found = true;
            }
        }
        if (options.size === 0) {
            // nothing to keep
            this.map.delete(hash);
        }
        return found;
    }
    forEach(callbackfn: (value: TValue, key: TKey, map: Map<TKey, TValue>) => void, thisArg?: any): void {
        for (const [_hash, options] of this.map) {
            for (const option of options) {
                callbackfn.call(thisArg, option.value, option.key, this);
            }
        }
    }
    get(key: TKey): TValue | undefined {
        const hash = this.hash(key);
        const options = this.map.get(hash);
        if (!options) return undefined;
        for (const option of options) {
            if (this.isEqual(option.key, key)) {
                return option.value;
            }
        }
        return undefined;
    }
    has(key: TKey): boolean {
        const hash = this.hash(key);
        const options = this.map.get(hash);
        if (!options) return false;
        for (const option of options) {
            if (this.isEqual(option.key, key)) {
                return true;
            }
        }
        return false;
    }
    set(key: TKey, value: TValue): this {
        const hash = this.hash(key);
        const options = this.map.get(hash);
        if (!options) {
            this.map.set(hash, new Set([{ key, value }]));
            return this;
        }
        let found = false;
        for (const option of options) {
            if (this.isEqual(option.key, key)) {
                option.value = value;
                found = true;
            }
        }
        if (!found) {
            // we have some stuff in the Set for this hash, but not this exact value
            options.add({ key, value });
        }
        return this;
    }
    update(key: TKey, valueFn: (prev?: TValue) => TValue): this {
        const hash = this.hash(key);
        const options = this.map.get(hash);
        if (!options) {
            this.map.set(hash, new Set([{ key, value: valueFn() }]));
            return this;
        }
        let found = false;
        for (const option of options) {
            if (this.isEqual(option.key, key)) {
                option.value = valueFn(option.value);
                found = true;
            }
        }
        if (!found) {
            // we have some stuff in the Set for this hash, but not this exact value
            options.add({ key, value: valueFn() });
        }
        return this;
    }
    get size(): number {
        return this.map.size;
    }
    *entries(): IterableIterator<[TKey, TValue]> {
        for (const [_hash, options] of this.map) {
            for (const option of options) {
                yield [option.key, option.value];
            }
        }
    }
    *keys(): IterableIterator<TKey> {
        for (const [_hash, options] of this.map) {
            for (const option of options) {
                yield option.key;
            }
        }
    }
    *values(): IterableIterator<TValue> {
        for (const [_hash, options] of this.map) {
            for (const option of options) {
                yield option.value;
            }
        }
    }
    [Symbol.iterator](): IterableIterator<[TKey, TValue]> {
        return this.entries();
    }
    readonly [Symbol.toStringTag] = 'HashMap';
}
