import { AxiosError, AxiosResponse } from 'axios';
import { MonoTypeOperatorFunction, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

interface NLPAPIResponseError {
    detail: any;
}

export default class NlpAPIError extends Error {
    constructor(error: AxiosError<NLPAPIResponseError>) {
        super(error.message);

        this.name = `NLP API Error (${error.response?.status || error.request?.status || 'Unspecified'})`;
        if (error.response) {
            this.detail = error.response?.data.detail;
        }
        if (error.stack) {
            this.stack = error.stack;
        }
    }

    detail: any;
}

export function isNlpAPIError(error: Error): error is NlpAPIError {
    return !!(error as NlpAPIError).detail;
}

/**
 * Rx operator wrapping errors thrown by the underlying API call in @class NlpAPIError
 * if they report to be coming from Axios codebase.
 */
export const mapNlpAPIError =
    <T extends AxiosResponse<unknown>>(): MonoTypeOperatorFunction<T> =>
    (source$: Observable<T>) =>
        source$.pipe(
            catchError((error: AxiosError) =>
                throwError(
                    error.isAxiosError ? new NlpAPIError(error as unknown as AxiosError<NLPAPIResponseError>) : error,
                ),
            ),
        );
