import { IndicatorImportRefViewModel } from '../../../web/src/contracts/dictionary-view-model';

export enum Column {
    name = 'name',
    ticker = 'ticker',
    last = 'last',
    change = 'change',
    percentageChange = 'percentageChange',
    marketCap = 'marketCap',
    volume = 'volume',
}

export const predefinedColumns = Object.keys(Column);

export const columnDisplayNames: ReadonlyMap<Column, string> = new Map<Column, string>([
    [Column.name, 'Name'],
    [Column.ticker, 'Ticker'],
    [Column.last, 'Price'],
    [Column.change, 'Chg'],
    [Column.percentageChange, 'Chg %'],
    [Column.marketCap, 'Market Cap'],
    [Column.volume, 'Vol'],
]);

export interface DefinedRow {
    rowId: number | undefined;
    name: string;
    ticker: string;
    last: number;
    change: number;
    percentageChange: number;
    marketCap: number;
    volume: number;
}

export interface Row extends DefinedRow {
    [key: string]: string | number | undefined;
}

export enum AppAccessStatus {
    uninitialized = 'uninitialized',
    initializedForPublicUse = 'initializedToPublic',
    initializedForPrivateUse = 'initializedForPrivateUse',
}

export type DefaultUniverseList = { id: string; displayName: string }[];
export interface UserDetails {
    id: string;
    name: string;
    username: string;
    email: string;
    isActive: boolean;
    isAdmin: boolean;
    isDeveloper: boolean;
}

export interface NlpSuggestion {
    input: string;
    formula: string;
    score?: number;
    type: 'operands' | 'conditions' | 'timeframes';
}

export type ResearchStatus = 'researching' | 'complete';

export interface NlpParseResponseData {
    docId: string;
    formula: string;
    aliases: IndicatorImportRefViewModel[];
    similar: NlpSuggestion[];
}
