import { ResearchStatus } from '../../types';
import { QueryAction } from '../actions/queries';

interface AskAlphaQueriesState {
    researchStatusByQuery: Record<string, undefined | ResearchStatus>;
    suggestionsByQuery: Record<string, string[]>;
    formulasByQuery: Record<string, string>;
    commonSuggestions: string[];
}

export const initialState: AskAlphaQueriesState = {
    researchStatusByQuery: {},
    suggestionsByQuery: {},
    formulasByQuery: {},
    commonSuggestions: [],
};

export const queries = (state = initialState, action: QueryAction): AskAlphaQueriesState => {
    switch (action.type) {
        case 'setSuggestionsForQuery': {
            return {
                ...state,
                suggestionsByQuery: { ...state.suggestionsByQuery, [action.query]: action.suggestions },
            };
        }

        case 'setFormulasForQueries': {
            return { ...state, formulasByQuery: { ...state.formulasByQuery, ...action.formulasByQuery } };
        }

        case 'setNaturalLanguageStatus': {
            return {
                ...state,
                researchStatusByQuery: { ...state.researchStatusByQuery, [action.query]: action.status },
            };
        }

        case 'setCommonSuggestions': {
            return { ...state, commonSuggestions: action.suggestions };
        }

        default:
            return state;
    }
};
