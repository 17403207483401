// type ArgumentTypes<T> = T extends (... args: infer U ) => infer R ? U: never;
// type ReplaceReturnType<T, TNewReturn> = (...a: ArgumentTypes<T>) => TNewReturn;
import { noop as rxnoop } from '../util/rxjs/noop';
import axios, { AxiosDefaults, AxiosInstance, AxiosInterceptorManager, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Observable, OperatorFunction } from 'rxjs';

export interface AxiosRxInstance {
    // (config: AxiosRequestConfig): AxiosPromise;
    // (url: string, config?: AxiosRequestConfig): AxiosPromise;
    defaults: AxiosDefaults;
    interceptors: {
        request: AxiosInterceptorManager<AxiosRequestConfig>;
        response: AxiosInterceptorManager<AxiosResponse>;
    };
    getUri(config?: AxiosRequestConfig): string;
    request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Observable<R>;
    get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Observable<R>;
    delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Observable<R>;
    head<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Observable<R>;
    options<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Observable<R>;
    post<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Observable<R>;
    put<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Observable<R>;
    patch<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Observable<R>;
}

export function rxifyAxios(
    instance: AxiosInstance = axios,
    extraOperator?: OperatorFunction<any, any>,
): AxiosRxInstance {
    const wrapped: AxiosRxInstance = {
        interceptors: instance.interceptors,
        defaults: instance.defaults,
        request: rxRequest.bind(instance) as any,
        getUri: instance.getUri,
        get: noPayloadWrapper('get', extraOperator).bind(instance) as any,
        delete: noPayloadWrapper('delete', extraOperator).bind(instance) as any,
        head: noPayloadWrapper('head', extraOperator).bind(instance) as any,
        options: noPayloadWrapper('options', extraOperator).bind(instance) as any,
        put: payloadWrapper('put', extraOperator).bind(instance) as any,
        post: payloadWrapper('post', extraOperator).bind(instance) as any,
        patch: payloadWrapper('patch', extraOperator).bind(instance) as any,
    };
    Object.freeze(wrapped);
    return wrapped;
}

export const rxAxios = rxifyAxios();

function rxRequest<T = any, R = AxiosResponse<T>>(
    this: AxiosInstance | undefined,
    req: AxiosRequestConfig,
): Observable<R> {
    return new Observable((sub) => {
        const cancelToken = axios.CancelToken;
        const source = cancelToken.source();
        const prom = (this ?? axios).request<T, R>({ ...req, cancelToken: source.token });
        prom.then(
            (res) => {
                sub.next(res);
                sub.complete();
            },
            (err) => {
                sub.error(err);
            },
        );

        return () => source.cancel();
    });
}

function noPayloadWrapper(method: 'get' | 'delete' | 'head' | 'options', extraOperator?: OperatorFunction<any, any>) {
    return function <T = any, R = AxiosResponse<T>>(
        this: AxiosInstance | undefined,
        url: string,
        config?: AxiosRequestConfig,
    ): Observable<R> {
        return new Observable((sub) => {
            const cancelToken = axios.CancelToken;
            const source = cancelToken.source();
            const prom = (this ?? axios)[method]<T, R>(url, { ...config, cancelToken: source.token });
            prom.then(
                (res) => {
                    sub.next(res);
                    sub.complete();
                },
                (err) => {
                    sub.error(err);
                },
            );

            return () => source.cancel();
        }).pipe(extraOperator ? extraOperator : rxnoop());
    };
}

function payloadWrapper<T = any, R = AxiosResponse<T>>(
    method: 'put' | 'post' | 'patch',
    extraOperator?: OperatorFunction<R, R>,
) {
    return function (
        this: AxiosInstance | undefined,
        url: string,
        data?: any,
        config?: AxiosRequestConfig,
    ): Observable<R> {
        return new Observable((sub) => {
            const cancelToken = axios.CancelToken;
            const source = cancelToken.source();
            const prom = (this ?? axios)[method]<T, R>(url, data, { ...config, cancelToken: source.token });
            prom.then(
                (res) => {
                    sub.next(res);
                    sub.complete();
                },
                (error) => {
                    sub.error(error);
                },
            );

            return () => source.cancel();
        }).pipe(extraOperator ? extraOperator : rxnoop<R>());
    };
}
