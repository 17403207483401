import { createInstance } from '../api';
import appConfig from '../config';

const axios = createInstance({ baseURL: appConfig.auth });

export const login = (username: string, password: string) => {
    return axios.post<{ token: string }>(`/login`, { username, password });
};

export const sendResetPasswordLink = (email: string) => {
    return axios.post<void>(`/sendResetLink`, {
        email,
    });
};

export const resetPassword = (password: string, resetToken: string) => {
    return axios.post<void>(`/resetPassword`, {
        password,
        resetToken,
    });
};
