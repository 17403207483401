import api from '../../../../web/src/api';
import appConfig from '../../../../web/src/config';
import { login } from '../../../../web/src/services/login';
import { LogoLink } from '../../LogoLink';
import { useDispatch } from '../../hooks';
import { setAccessToken, setUserDetails } from '../../store/actions';
import { UserDetails } from '../../types';
import React, { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const LoginPage: FC<{}> = () => {
    const dispatch = useDispatch();

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();

    const onUsernameChanged = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setUsername(e.target.value);
        },
        [setUsername],
    );

    const onPasswordChanged = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e.target.value);
        },
        [setPassword],
    );

    const onLogin = useCallback(
        async (e: React.FormEvent) => {
            e.preventDefault();
            try {
                if (!username || !password) return setError('Please enter username and password');

                const res = await login(username, password);
                const { token } = res.data;

                if (!token) {
                    return setError('Invalid username or password');
                } else {
                    dispatch(setAccessToken(token));

                    const url = `${appConfig.auth}/users/me`;

                    const userResponse: { data: UserDetails } | undefined = await api.get(url, {
                        headers: { Authorization: `Bearer ${token}` },
                    });

                    if (userResponse?.data) {
                        dispatch(setUserDetails(userResponse.data));
                        navigate('/');
                    }
                }
            } catch (e) {
                return setError('Invalid username or password');
            }
        },
        [username, password, setError, dispatch, navigate],
    );

    return (
        <div className="grid h-screen place-items-center">
            <div className="flex flex-col items-center">
                <LogoLink />
                <form onSubmit={onLogin} className="w-96">
                    <div className="mb-4">
                        <input
                            type="text"
                            className="form-control text-gray-750 focus:text-gray-750 m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal transition ease-in-out focus:border-blue-600 focus:bg-white focus:outline-none"
                            placeholder="Username"
                            value={username}
                            onChange={onUsernameChanged}
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="password"
                            className="form-control text-gray-750 focus:text-gray-750 m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal transition ease-in-out focus:border-blue-600 focus:bg-white focus:outline-none"
                            placeholder="Password"
                            value={password}
                            onChange={onPasswordChanged}
                        />
                    </div>
                    <div className="mb-6 pt-1 pb-1 text-center">
                        <button
                            className="mb-3 inline-block w-full rounded border border-solid border-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:text-white hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            type="submit"
                            data-mdb-ripple="true"
                            data-mdb-ripple-color="light"
                        >
                            Log in
                        </button>
                    </div>
                    <div className={`rounded-lg rounded-sm bg-red-100 p-4 text-red-700 ${error ? '' : 'hidden'}`}>
                        {error}
                    </div>
                </form>
            </div>
        </div>
    );
};
