import { UnexpectedTimestampError } from '../appError';
import { ChronoUnit, DateTimeFormatter, Duration, Instant, LocalDateTime, ZonedDateTime } from '@js-joda/core';
import { Locale } from '@js-joda/locale_en-us';

export const getSystemOffset = () => ZonedDateTime.now().withFixedOffsetZone().offset();

export const isSameDay = (a: Instant | undefined, b: Instant | undefined): boolean => {
    if (!a || !b) {
        return false;
    }

    return LocalDateTime.ofInstant(a)
        .truncatedTo(ChronoUnit.DAYS)
        .equals(LocalDateTime.ofInstant(b).truncatedTo(ChronoUnit.DAYS));
};

export const humanFormatter = DateTimeFormatter.ofPattern('eeee dd, YYYY hh:mm a').withLocale(Locale.ENGLISH);

export const humanizeDuration = (duration: Duration): string => {
    if (duration.toDays() >= 401) return `${Math.floor((duration.toDays() / 365) * 10) / 10} years`; // only plural if >= 1.1 years
    if (duration.toDays() >= 365) return `${Math.floor((duration.toDays() / 365) * 10) / 10} year`;
    if (duration.toDays() >= 34) return `${Math.floor((duration.toDays() / 30) * 10) / 10} months`; // only plural if >= 1.1 months
    if (duration.toDays() >= 30) return `${Math.floor((duration.toDays() / 30) * 10) / 10} month`;
    if (duration.toDays() > 1) return `${duration.toDays()} days`;
    if (duration.toDays() === 1) return '1 day';
    if (duration.toHours() > 1) return `${duration.toHours()} hours`;
    if (duration.toHours() === 1) return '1 hour';
    if (duration.toMinutes() > 1) return `${duration.toMinutes()} minutes`;
    if (duration.toMinutes() === 1) return '1 minute';
    if (duration.seconds() > 1) return `${duration.seconds()} seconds`;
    if (duration.seconds() === 1) return '1 second';
    if (duration.toMillis() > 1) return `${duration.toMillis()} millis`;
    if (duration.toMillis() === 1) return '1 milli';
    if (duration.toNanos() > 1) return `${duration.toNanos()} nanos`;
    if (duration.toNanos() === 1) return '1 nano';

    return 'now';
};

export const parseNumber = (timestamp: number): Instant => {
    try {
        const millis = Math.trunc(timestamp);
        let time = Instant.ofEpochMilli(millis);

        if (millis !== timestamp) {
            const nanos = Math.trunc((timestamp % 1) * 1e9);
            time = time.plusNanos(nanos);
        }

        return time;
    } catch (e) {
        throw UnexpectedTimestampError.create('Failed to parse timestamp', { timestamp, cause: e });
    }
};

/**
 * Keys which are used to define/reference custom format strings in an @date-io/utils format dictionary
 */
export const UtilsFormatKeys = {
    LocalHourMinutesWithMeridien: 'localHourMinutesWithMeridien',
    FullDateTimeWithSeconds: 'fullDateTimeWithSeconds',
    KeyboardDateTimeWithSeconds: 'keyboardDateTimeWithSeconds',
} as const;
