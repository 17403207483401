import { useDispatch, useSelector } from '../../hooks';
import { logout } from '../../store/actions';
import React, { useCallback } from 'react';

export const Header: React.FC = () => {
    const userDetails = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    const onLogout = useCallback(() => dispatch(logout()), [dispatch]);

    return (
        <header className="header sticky top-0 mb-6 flex items-center justify-between bg-white pt-4">
            <div className="ml-auto flex w-1/2 items-center justify-end">
                <div className="mr-4 text-xs">
                    {userDetails?.profile?.firstName} {userDetails?.profile?.lastName}
                </div>
                <button
                    className="rounded border border-solid border-gray-300 px-4 py-2 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:text-white hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg "
                    onClick={onLogout}
                >
                    Logout
                </button>
            </div>
        </header>
    );
};
