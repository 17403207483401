import {
    IndicatorFormulaViewModel,
    IndicatorImportRefViewModel,
} from '../../../../web/src/contracts/dictionary-view-model';
import { Strategy, StrategyInstance, StrategyType } from '../../../../web/src/contracts/strategy';
import { addTagsToPlan } from '../../../../web/src/if-then/if-then-helpers';
import { fetchSuggestions } from '../../../../web/src/services/nlp';
import { playStrategy } from '../../../../web/src/services/strategies';
import { firstValueFrom } from 'rxjs';

// Dupe of projects/web/src/services/runInfo.ts
// We need to get a third project going soon and resolve this

export async function getRunInfoFromNLP(query: string, columnTemplate: string, universe: string | null) {
    const { formula, aliases } = await firstValueFrom(fetchSuggestions(query, 'askAlpha'));

    if (!formula || !aliases) return { strategyInstance: undefined, validationColumnNameLookup: undefined };

    return getRunInfoFromFormula(formula, aliases, columnTemplate, universe);
}

export async function getRunInfoFromFormula(
    formula: string,
    imports: IndicatorImportRefViewModel[],
    columnTemplate: string | null,
    universe: string | null,
) {
    const plan: IndicatorFormulaViewModel = { formula, imports };
    const { planWithValidationTags, validationColumnNameLookup } = addTagsToPlan(plan);

    const strategy: Strategy = {
        name: null,
        description: null,
        defaultUniverseId: null,
        defaultColumnTemplateId: null,
        isTemplate: false,
        type: StrategyType.formula,
        plan: planWithValidationTags,
    };

    let strategyInstance: StrategyInstance | undefined;
    try {
        strategyInstance = await firstValueFrom(
            playStrategy({
                strategies: [strategy],
                columnTemplate,
                universe: universe,
                includeTags: true,
                scrolling: false,
            }),
        );
    } catch {
        return { strategyInstance: undefined, validationColumnNameLookup: undefined };
    }

    return {
        strategyInstance,
        validationColumnNameLookup,
    };
}
