import { auth } from '../../../../web/src/store/reducers/auth';
import { ui } from '../../../../web/src/store/reducers/ui';
import { app } from './app';
import { queries } from './queries';
import { combineReducers } from 'redux';

export const rootReducer = combineReducers({
    auth,
    app,
    queries,
    ui, // needed to get indicators working, apparently.
});
