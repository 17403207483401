import { MutableRefObject, RefObject, useRef } from 'react';

const UNINITIALIZED = Symbol('UNINITIALIZED');

// export function useLazyRef<T>(initialValueFactory: () => T | null): RefObject<T>;
export function useLazyRef<T>(initialValueFactory: () => T): MutableRefObject<T>;

export function useLazyRef<T>(initialValueFactory: () => T): RefObject<T> {
    const ref = useRef<T | typeof UNINITIALIZED>(UNINITIALIZED);
    if (ref.current === UNINITIALIZED) {
        ref.current = initialValueFactory();
    }
    return ref as RefObject<T>;
}

export default useLazyRef;
