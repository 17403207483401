import thinkAlphaLogo from '../../web/src/assets/ThinkAlphaLogoNoType.svg';
import React from 'react';
import { Link } from 'react-router-dom';

interface IconLinkProps {
    className?: string;
}

export const IconLink: React.FC<IconLinkProps> = ({ className }) => {
    return (
        <Link to="/">
            <img className={`w-16 ${className ?? ''}`} src={thinkAlphaLogo} />
        </Link>
    );
};
