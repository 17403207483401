import { useDispatch, useSelector } from '../../hooks';
import { researchNaturalLanguage } from '../../store/actions/queries';
import { Autocomplete, IconButton, InputAdornment, Popper, TextField } from '@mui/material';
import React, { ReactNode, useCallback, useState } from 'react';

/**
 * Returns all options, because we control the NLP suggestions and we don't
 * want them filtered by strict text matching
 */
function filterOptions(x: any) {
    return x;
}

interface QueryFieldProps {
    value: string;
    setValue: (value: string) => void;
    onSubmit: (value: string) => void;
    inputEndAdornment?: ReactNode;
}

export const QueryField: React.FC<QueryFieldProps> = ({ value, setValue, onSubmit, inputEndAdornment }) => {
    const dispatch = useDispatch();
    const [showAutocomplete, setShowAutoComplete] = useState(false);
    const openAutocomplete = useCallback(() => {
        setShowAutoComplete(true);
    }, [setShowAutoComplete]);

    const closeAutocomplete = useCallback(() => {
        setShowAutoComplete(false);
    }, [setShowAutoComplete]);

    const suggestions = useSelector((state) => state.queries.suggestionsByQuery[value]);
    const commonSuggestions = useSelector((state) => state.queries.commonSuggestions);

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                onSubmit(value);
            }}
            className="w-full max-w-[38rem]"
        >
            <Autocomplete
                open={showAutocomplete}
                onOpen={openAutocomplete}
                onClose={closeAutocomplete}
                onChange={(_event, newValue, details) => {
                    setValue(newValue);

                    if (details === 'selectOption') {
                        closeAutocomplete();
                        onSubmit(newValue);
                    }
                }}
                onInputChange={(_event, value) => {
                    setValue(value);
                    if (value) {
                        dispatch(researchNaturalLanguage(value));
                    }
                }}
                inputValue={value}
                options={suggestions?.length > 0 ? suggestions : commonSuggestions}
                freeSolo
                filterOptions={filterOptions}
                value={value}
                selectOnFocus
                handleHomeEndKeys
                disableClearable
                size="small"
                PopperComponent={(props) => {
                    return (
                        <Popper
                            nonce={undefined}
                            onResize={undefined}
                            onResizeCapture={undefined}
                            {...props}
                            container={document.getElementById('app')}
                        />
                    );
                }}
                classes={{
                    paper: 'bg-white dark:bg-stone-900 text-stone-900 dark:text-stone-100',
                    option: 'hover:bg-stone-100 dark:hover:bg-stone-800',
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        // onKeyDown={checkKeyDownForSubmit}
                        className="query-field"
                        placeholder="Type your financial search here"
                        variant="outlined"
                        autoFocus
                        classes={{
                            root: 'block mr-[0.5rem]',
                        }}
                        InputProps={{
                            ...params.InputProps,
                            className: '',
                            classes: {
                                root: 'rounded-full pl-[1rem] border text-stone-900 dark:text-stone-100 border-stone-300 dark:border-stone-600',
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton edge="start" size="small" type="submit">
                                        <i className="far fa-search text-stone-500 dark:text-stone-400" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            endAdornment: inputEndAdornment,
                        }}
                    />
                )}
            />
        </form>
    );
};
